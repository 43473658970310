import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import styles from './tooltip.scss';
import withFontClassName from '../../hoc/with-font-class-name';

export const Tooltip = ({ children, style, placement, offset, contentFontClassName, small }) => {
  if (style && style.top && offset) {
    style = { ...style, top: style.top - offset };
  }
  return (
    <div
      className={classNames(
        small ? styles.small : '',
        styles.container,
        { [styles[placement]]: placement },
        contentFontClassName,
      )}
      style={style}
    >
      {children}
      <span className={styles.triangle} />
    </div>
  );
};

Tooltip.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,
  placement: PropTypes.string,
  offset: PropTypes.number,
  small: PropTypes.bool,
  contentFontClassName: PropTypes.string.isRequired,
};

export default withFontClassName(Tooltip);
