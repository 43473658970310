import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import SimpleRenderer from '../simple-renderer';
import styles from './content-excerpt.scss';

const ContentExcerpt = ({ content, query, type, className, isContentConverted, clamp }) => {
  const containerClassName = classNames(styles.container, styles[type], className);
  return (
    <div className={containerClassName} data-hook="post-description">
      <SimpleRenderer
        clamp={clamp}
        isContentConverted={isContentConverted}
        contentState={content}
        query={query}
      />
    </div>
  );
};

ContentExcerpt.SMALL = 'small';
ContentExcerpt.MEDIUM = 'medium';
ContentExcerpt.SEARCH = 'search';
ContentExcerpt.FOOTER_POST = 'footerPost';

ContentExcerpt.propTypes = {
  content: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  query: PropTypes.string,
  type: PropTypes.string,
  hashtags: PropTypes.arrayOf(PropTypes.string),
  isContentConverted: PropTypes.bool,
  clamp: PropTypes.number,
};

ContentExcerpt.defaultProps = {
  clamp: 4,
};

export default ContentExcerpt;
