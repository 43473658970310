import { flowRight } from 'lodash';
import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import withTranslate from '../../../common/components/with-translate/with-translate';
import NewContentIndicator from '../new-content-indicator';
import { PinIcon } from '../icons/pin-icon';
import MoreButtonUniversalPost from '../more-button-universal/more-button-universal-post';
import CardHeaderLeftSide from './card-header-left-side';
import styles from './post-list-card-header.scss';

class PostListCardHeader extends Component {
  render() {
    const { post, origin, t, isPostNew } = this.props;
    const owner = post.owner;
    const date = post.createdDate;

    return (
      <div>
        <div className={styles.header}>
          <CardHeaderLeftSide user={owner} date={date} />

          <div className={styles.headerRightSide}>
            {post.isPinned && (
              <PinIcon
                className={classNames('icon-secondary-fill', styles.pinnedIcon)}
                aria-label={t('post-list-item.pinned-post')}
              />
            )}

            <MoreButtonUniversalPost
              post={post}
              origin={origin}
              className={styles.moreButton}
              isMobile
            />
          </div>
        </div>
        {isPostNew && (
          <div className={styles.indicatorWrapper}>
            <NewContentIndicator
              className={styles.newIndicator}
              hideNumber
              count={isPostNew && 1}
            />
          </div>
        )}
      </div>
    );
  }
}

PostListCardHeader.propTypes = {
  post: PropTypes.object.isRequired,
  origin: PropTypes.string.isRequired,
  isPostNew: PropTypes.bool,
};

export default flowRight(withTranslate)(PostListCardHeader);
