import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Avatar from '../avatar';
import styles from './sm-list-compact.scss';

class SmListCompact extends Component {
  render() {
    const { maxHeight, items, lastItem } = this.props;
    return (
      <div style={{ maxHeight }} className={styles.container} data-hook="sm-compact-list">
        <ul className={styles.list}>
          {items?.map(user => (
            <li className={styles.listItem}>
              <Avatar type={Avatar.WHO_LIKED} user={user} />
            </li>
          ))}
          {lastItem && <li>{lastItem}</li>}
        </ul>
      </div>
    );
  }
}

SmListCompact.propTypes = {
  items: PropTypes.array,
  maxHeight: PropTypes.number,
};

export default SmListCompact;
