import { isContentStateEmpty } from './content-state-utils';
import hasActiveUploads from './has-active-uploads';

export default {
  formName: 'comment',
  resetOnUnmount: true,
  validate: ({ content }) => {
    if (isContentStateEmpty(content)) {
      return { content: 'empty' };
    }

    if (hasActiveUploads(content)) {
      return { content: 'uploading' };
    }
  },
};
