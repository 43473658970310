import { find, invoke, some } from 'lodash';

const YOUTUBE_PATTERNS = [
  () => /https?:\/\/www\.youtube\.com\/embed\/([a-z\-_\d]+)/gi,
  () => /https?:\/\/www\.youtube\.com\/watch\?v=([a-z\-_\d]+)/gi,
  () => /https?:\/\/m\.youtube\.com\/watch\?v=([a-z\-_\d]+)/gi,
  () => /https?:\/\/youtu\.be\/([a-z\-_\d]+)/gi,
];
const VIMEO_PATTERNS = [
  () => /https?:\/\/vimeo\.com\/(\d+)/gi,
  () => /https:\/\/player\.vimeo\.com\/video\/(\d+)/gi,
];
const WIX_MP_PATTERNS = [() => /https?:\/\/video.wixstatic.com\/(.+)/gi];

const _isSupported = (patterns, url) => {
  let result = false;
  patterns
    .map(fn => fn())
    .forEach(pattern => {
      result = result || Boolean(pattern.exec(url));
    });
  return result;
};

const extractVideoId = (patterns, url) => {
  let result;
  patterns
    .map(fn => fn())
    .forEach(pattern => {
      const matches = pattern.exec(url);
      if (matches) {
        result = matches[1];
      }
    });
  return result;
};

const PROVIDERS = [
  {
    isSupported: url => _isSupported(YOUTUBE_PATTERNS, url),
    buildEmbedUrl: url => {
      const videoId = extractVideoId(YOUTUBE_PATTERNS, url);
      return `https://www.youtube.com/embed/${videoId}?enablejsapi=1`;
    },
    buildUrl: url => {
      const videoId = extractVideoId(YOUTUBE_PATTERNS, url);
      return `https://youtu.be/${videoId}`;
    },
    isLoaderVisible: true,
  },
  {
    isSupported: url => _isSupported(VIMEO_PATTERNS, url),
    buildEmbedUrl: url => {
      const videoId = extractVideoId(VIMEO_PATTERNS, url);
      return `https://player.vimeo.com/video/${videoId}?api=1`;
    },
    buildUrl: url => {
      const videoId = extractVideoId(VIMEO_PATTERNS, url);
      return `https://vimeo.com/${videoId}`;
    },
    isLoaderVisible: true,
  },
  {
    isSupported: url => _isSupported(WIX_MP_PATTERNS, url),
    buildEmbedUrl: () => null,
    buildUrl: url => url,
    isLoaderVisible: false,
  },
];

export const getProvider = url => {
  return find(PROVIDERS, provider => provider.isSupported(url));
};

export const isSupported = url => {
  return some(PROVIDERS, provider => provider.isSupported(url));
};

export const buildEmbedUrl = url => invoke(getProvider(url), 'buildEmbedUrl', url);

export const buildUrl = url => invoke(getProvider(url), 'buildUrl', url);
