import { take, uniq } from 'lodash';
import classNames from 'classnames';
import React from 'react';
import styles from './hashtags.scss';
import { encodeQuery } from '../../services/query-encoding';
import Link from '../link/internal-link';
import PropTypes from 'prop-types';

const MAX_HASHTAGS = 5;

const Hashtags = ({ hashtags, className, dataHook }) => {
  return (
    <div
      className={classNames(className, styles.hashtags, 'forum-links-hashtags-color')}
      data-hook={dataHook}
    >
      {take(uniq(hashtags), MAX_HASHTAGS).map(hashtag => (
        <span className={styles.hashtagContainer} key={hashtag}>
          <Link
            className={classNames('forum-links-hashtags-color', styles.hashtag)}
            to={`/search/${encodeQuery(`#${hashtag}`)}`}
          >
            #{hashtag}
          </Link>
          &nbsp;
        </span>
      ))}
    </div>
  );
};

Hashtags.propTypes = {
  hashtags: PropTypes.arrayOf(PropTypes.string).isRequired,
  className: PropTypes.string,
};

export default Hashtags;
