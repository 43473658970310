import {
  getAppSettingsValue,
  IS_RCE_EVENTS_EMBED_ENABLED_PATH,
  IS_RCE_BOOKINGS_EMBED_ENABLED_PATH,
  IS_RCE_STORES_EMBED_ENABLED_PATH,
} from '@wix/communities-forum-client-commons';

import { VERTICALS } from '../constants/vertical-embed';

export const getInstalledVerticals = state => state.verticalEmbed.installedApps;

export const getEnabledVerticalEmbedPlugins = style => {
  const getValue = key => getAppSettingsValue({ style, key, fallback: true });
  return [
    getValue(IS_RCE_EVENTS_EMBED_ENABLED_PATH) && VERTICALS.EVENT,
    getValue(IS_RCE_BOOKINGS_EMBED_ENABLED_PATH) && VERTICALS.BOOKING,
    getValue(IS_RCE_STORES_EMBED_ENABLED_PATH) && VERTICALS.STORES,
  ].filter(Boolean);
};

export const getVisibleVerticalEmbeds = (state, style) => {
  const enabledPlugins = getEnabledVerticalEmbedPlugins(style);
  const installedPlugins = getInstalledVerticals(state);
  return enabledPlugins.filter(plugin => installedPlugins.includes(plugin));
};
