import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from '../../../common/components/runtime-context';
import BackButton from '../../components/header-back-button';
import HeaderButtons from '../../components/header-buttons';
import HeaderTitle from '../../components/header-title';
import Header from '../../components/header';
import { getCategories } from '../../../common/selectors/categories-selectors';
import withMainPageEnabled from '../../hoc/with-main-page-enabled';
import CategoriesSelect from '../../components/categories-select';
import styles from './category-page-header.scss';
import withExperiment from '../../hoc/with-experiment';
import { EXPERIMENT_FORUM_TABS } from '@wix/communities-forum-client-commons';
import HeaderTabs from '../header-navigation/header-navigation.component';

const CategoryPageHeader = ({
  categories,
  menu,
  params,
  currentCategorySlug,
  isMainPageEnabled,
  isForumTabsEnabled,
}) => {
  const hasBackButton = currentCategorySlug && isMainPageEnabled;
  const LeftComponent = isForumTabsEnabled ? HeaderTabs : CategoriesSelect;
  const HeaderComponent = isForumTabsEnabled ? HeaderTabs : HeaderTitle;
  const left = (
    <div className={styles.leftSideWrapper}>
      {hasBackButton && <BackButton className={styles.backBtn} />}
      {hasBackButton ? (
        <div className={styles.center}>
          <LeftComponent />
        </div>
      ) : (
        <LeftComponent />
      )}
    </div>
  );

  if (categories.length === 0) {
    return <Header left={<HeaderComponent />} />;
  } else if (categories.length === 1) {
    return (
      <Header left={<HeaderComponent />} right={<HeaderButtons params={params} />} menu={menu} />
    );
  } else {
    return <Header left={left} right={<HeaderButtons params={params} />} menu={menu} />;
  }
};

CategoryPageHeader.propTypes = {
  params: PropTypes.object.isRequired,
  categories: PropTypes.array,
  menu: PropTypes.node,
  currentCategorySlug: PropTypes.string,
  isMainPageEnabled: PropTypes.bool,
  isForumTabsEnabled: PropTypes.bool,
};

const mapRuntimeToProps = (state, ownProps) => ({
  categories: getCategories(state),
  currentCategorySlug: ownProps.params.categorySlug,
});

export default flowRight(
  connect(mapRuntimeToProps),
  withMainPageEnabled,
  withExperiment({
    isForumTabsEnabled: EXPERIMENT_FORUM_TABS,
  }),
)(CategoryPageHeader);
