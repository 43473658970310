import getMainTheme from './theme';
import styles from './theme-reply.scss';
import { mergeStyles } from './utils';

export default function getTheme(args) {
  return mergeStyles(getMainTheme(args), {
    footerToolbar: styles.footerToolbar,
    footerToolbar_buttons: styles.footerToolbar_buttons,
    wrapper: styles.wrapper,
    editor: styles.editor,
    atomic: styles.atomic,
    desktop: styles.desktop,
    sizeContent: styles.sizeContent,
    sizeFullWidth: styles.sizeFullWidth,
    text: styles.text,
    file_upload_container: styles.file_upload_container,
    file_upload_name: styles.file_upload_name,
    'divider--large': styles['divider--large'],
    'divider-container': styles['divider-container'],
  });
}
