import { identity, memoize } from 'lodash';
import { fileToDataUrl, resizeImage } from '../../services/process-image';

// this is used on component side, to wrap uploadImage action, it does some thing which need access to DOM
export const wrapUploadImageAction = memoize((uploadImage, isMobile) => (file, props) => {
  return fileToDataUrl(file)
    .then(isMobile ? resizeImage : identity)
    .then(imageData => {
      const promise = uploadImage(file, imageData, props);
      return { promise, imageData };
    });
});
