import PropTypes from 'prop-types';
import React from 'react';
import CommentFormWrapper from '../comment-form-wrapper';
import styles from './post-page-footer-wrapper.scss';

class PostPageFooterWrapper extends React.Component {
  render() {
    const { isMobile, children } = this.props;

    if (!isMobile) {
      return (
        <div className={styles.container}>
          <CommentFormWrapper>{children}</CommentFormWrapper>
        </div>
      );
    } else {
      return <div className={styles.container}>{children}</div>;
    }
  }
}

PostPageFooterWrapper.propTypes = {
  children: PropTypes.node,
  isTyping: PropTypes.bool,
  isMobile: PropTypes.bool,
};

export default PostPageFooterWrapper;
