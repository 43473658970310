import React from 'react';
import classNames from 'classnames';
import Pagination from '../pagination';
import styles from './pagination-regular.scss';

const PaginationRegular = props => (
  <Pagination
    className={styles.container}
    arrowClass={styles.arrow}
    innerClass={styles.page}
    activeClass={classNames(styles.active)}
    {...props}
  />
);

export default PaginationRegular;
