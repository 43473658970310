import PropTypes from 'prop-types';
import React from 'react';
import MobileCtaButton from '../mobile-cta-button';

const CreatePostCard = ({ onClick, showQuestionLabel, createPostCtaLabel, noPadding }) => {
  const label = createPostCtaLabel
    ? createPostCtaLabel
    : showQuestionLabel
    ? 'create-post-card.create-new-question'
    : 'create-post-card.create-post';

  return (
    <MobileCtaButton
      onClick={onClick}
      label={label}
      dataHook="create-post-cta"
      noPadding={noPadding}
    />
  );
};

CreatePostCard.propTypes = {
  onClick: PropTypes.func,
  createPostCtaLabel: PropTypes.string,
  showQuestionLabel: PropTypes.bool,
};

CreatePostCard.defaultProps = {
  showQuestionLabel: false,
};

export default CreatePostCard;
