import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CardHeaderLeftSide from './card-header-left-side';
import MoreButtonUniversalComment from '../more-button-universal/more-button-universal-comment';
import styles from './comment-list-card-header.scss';

class CommentListCardHeader extends Component {
  render() {
    const { comment, origin } = this.props;
    const owner = comment.owner;
    const date = comment.createdDate;

    return (
      <div className={styles.header}>
        <CardHeaderLeftSide user={owner} date={date} />
        <div className={styles.headerRightSide}>
          <MoreButtonUniversalComment
            comment={comment}
            origin={origin}
            className={styles.moreButton}
            isMobile
          />
        </div>
      </div>
    );
  }
}

CommentListCardHeader.propTypes = {
  comment: PropTypes.object.isRequired,
  origin: PropTypes.string.isRequired,
};

export default CommentListCardHeader;
