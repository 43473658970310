import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ActionButton from '../action-button/action-button';
import ensureAuth from '../../hoc/ensure-auth';
import { FOLLOW_CATEGORY } from '../../constants/interactions';
import FollowCategoryHeadless from '../follow-category-headless';

const ActionButtonAuth = ensureAuth(ActionButton);

export class FollowButton extends Component {
  render() {
    const { category, children, className, size } = this.props;
    return (
      <FollowCategoryHeadless category={category}>
        {({ onClick, can }) => {
          const ActionComponent = can ? ActionButton : ActionButtonAuth;
          return (
            <ActionComponent
              isButton
              dataHook="follow-button"
              onClick={onClick}
              className={className}
              size={size}
              actionDetails={{ action: FOLLOW_CATEGORY, args: [category._id] }}
            >
              {children}
            </ActionComponent>
          );
        }}
      </FollowCategoryHeadless>
    );
  }
}

FollowButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  category: PropTypes.object.isRequired,
  size: PropTypes.string,
};

FollowButton.SIZE_SMALL = ActionButton.SIZE_SMALL;

export default FollowButton;
