import PropTypes from 'prop-types';
import React from 'react';
import CategorySelect from '../../components/category-select';
import BreadcrumbsWidget from '../breadcrumbs-widget';
import DesktopHeader from '../desktop-header';
import { renderSearchInput } from './render-search-input';

const InternalPageCategorySelectDesktopHeader = ({ params, ...props }) => (
  <DesktopHeader
    left={<CategorySelect params={params} />}
    right={<BreadcrumbsWidget searchInput={renderSearchInput()} params={params} />}
    {...props}
  />
);

InternalPageCategorySelectDesktopHeader.propTypes = {
  params: PropTypes.object.isRequired,
};

export default InternalPageCategorySelectDesktopHeader;
