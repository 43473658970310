import React from 'react';
import forDirection from '../../hoc/for-direction';

export const ArrowSubcategoriesIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="19" viewBox="0 0 19 19" {...props}>
    <path
      d="M1.87401173,9.0171571 L11.9171573,9.0171571 L11.9171573,2.2 C11.9171573,2.08954305 12.0067003,2 12.1171573,2 L12.7171573,2 C12.8276142,2 12.9171573,2.08954305 12.9171573,2.2 L12.9171573,9.2171571 L12.9171573,9.8171571 C12.9171573,9.92761405 12.8276142,10.0171571 12.7171573,10.0171571 L1.78872997,10.0171571 L4.7254834,12.9539105 C4.80358826,13.0320154 4.80358826,13.1586484 4.7254834,13.2367532 L4.30121933,13.6610173 C4.22311447,13.7391222 4.09648148,13.7391222 4.01837662,13.6610173 L0.0585786438,9.70121933 C0.0195262146,9.6621669 -7.90478794e-14,9.61098244 -7.81597009e-14,9.55979797 C-1.00364161e-13,9.50861351 0.0195262146,9.45742905 0.0585786438,9.41837662 L4.01837662,5.45857864 C4.09648148,5.38047379 4.22311447,5.38047379 4.30121933,5.45857864 L4.7254834,5.88284271 C4.80358826,5.96094757 4.80358826,6.08758057 4.7254834,6.16568542 L1.87401173,9.0171571 Z"
      transform="translate(6.458579, 7.859798) scale(-1, 1) translate(-6.458579, -7.859798) "
    />
  </svg>
);

export const ArrowSubcategoriesIconRtl = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="19" viewBox="0 0 19 19" {...props}>
    <path d="M7.87401173,9.0171571 L17.9171573,9.0171571 L17.9171573,2.2 C17.9171573,2.08954305 18.0067003,2 18.1171573,2 L18.7171573,2 C18.8276142,2 18.9171573,2.08954305 18.9171573,2.2 L18.9171573,9.2171571 L18.9171573,9.8171571 C18.9171573,9.92761405 18.8276142,10.0171571 18.7171573,10.0171571 L7.78872997,10.0171571 L10.7254834,12.9539105 C10.8035883,13.0320154 10.8035883,13.1586484 10.7254834,13.2367532 L10.3012193,13.6610173 C10.2231145,13.7391222 10.0964815,13.7391222 10.0183766,13.6610173 L6.05857864,9.70121933 C6.01952621,9.6621669 6,9.61098244 6,9.55979797 C6,9.50861351 6.01952621,9.45742905 6.05857864,9.41837662 L10.0183766,5.45857864 C10.0964815,5.38047379 10.2231145,5.38047379 10.3012193,5.45857864 L10.7254834,5.88284271 C10.8035883,5.96094757 10.8035883,6.08758057 10.7254834,6.16568542 L7.87401173,9.0171571 Z" />
  </svg>
);

export default forDirection(ArrowSubcategoriesIcon, ArrowSubcategoriesIconRtl);
