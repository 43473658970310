import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import withFontClassName from '../../hoc/with-font-class-name';
import withCardBorderWidth from '../../hoc/with-card-border-width';
import styles from './empty-states.scss';
import { connect } from '../../../common/components/runtime-context';
import { getCategoryFromLocation } from '../../../common/selectors/categories-selectors';

class EmptyStates extends React.Component {
  componentDidMount() {
    const { categoryId, type, userEventsShowEmptyState, whenWorkerInitialized } = this.props;
    whenWorkerInitialized.then(() => {
      type &&
        userEventsShowEmptyState({
          categoryId,
          type,
        });
    });
  }

  render() {
    const {
      title,
      content,
      className,
      children,
      titleFontClassName,
      contentFontClassName,
      borderWidth,
      borderless = false,
      hideChildren = false,
    } = this.props;
    const containerClassName = classNames(
      styles.container,
      className,
      contentFontClassName,
      'forum-card-background-color',
      'forum-text-color',
      borderless ? '' : styles.containerBorder,
      borderless ? '' : 'forum-card-border-color',
    );
    return (
      <div className={containerClassName} style={{ borderWidth }}>
        <div className={titleFontClassName}>
          <div className={styles.header} data-hook="empty-states__header">
            {title}
          </div>
        </div>
        <div className={styles.content} data-hook="empty-states__content">
          {content}
        </div>
        {children && !hideChildren && (
          <div className={styles.controls} data-hook="empty-states__sign-up">
            {children}
          </div>
        )}
      </div>
    );
  }
}

EmptyStates.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  className: PropTypes.string,
  titleFontClassName: PropTypes.string.isRequired,
  contentFontClassName: PropTypes.string.isRequired,
  borderWidth: PropTypes.number.isRequired,
  categoryId: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  whenWorkerInitialized: PropTypes.func.isRequired,
  borderless: PropTypes.bool,
  hideChildren: PropTypes.bool,
};

const mapRuntimeToProps = (state, ownProps, actions, host, whenWorkerInitialized) => {
  const currentCategory = getCategoryFromLocation(state);
  return {
    userEventsShowEmptyState: actions.userEventsShowEmptyState,
    categoryId: currentCategory._id,
    whenWorkerInitialized,
  };
};

export default flowRight(
  connect(mapRuntimeToProps),
  withFontClassName,
  withCardBorderWidth,
)(EmptyStates);
