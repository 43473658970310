import React from 'react';

export const RepliesIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" {...props}>
    <g fillRule="evenodd">
      <path d="M19 15a4 4 0 0 0-4 4v1h-1v-1a5 5 0 0 1 5-5l2.293-.003-2.145-2.144a.5.5 0 1 1 .707-.707l3.352 3.351-3.352 3.354a.5.5 0 1 1-.707-.707l2.145-2.147L19 15z" />
      <path
        fillRule="nonzero"
        d="M9 18.62l2.744-1.64a.5.5 0 0 1 .512.86l-3.5 2.09A.5.5 0 0 1 8 19.5V17H6.237C4.9 17 4 16.225 4 14.934V7.247C4 5.912 4.785 5 6.077 5h11.82C19.2 5 20 5.861 20 7.18V8.5a.5.5 0 1 1-1 0V7.18C19 6.399 18.63 6 17.898 6H6.078C5.372 6 5 6.434 5 7.247v7.687C5 15.637 5.422 16 6.237 16H8.5a.51.51 0 0 1 .01 0H9v2.62z"
      />
    </g>
  </svg>
);
