import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from '../../../common/components/runtime-context';
import withTranslate from '../../../common/components/with-translate/with-translate';
import { withCategoryActions } from './with-category-actions';
import { mapCategoryActionsToItems } from './category-actions-utils';

export class CategoryActions extends Component {
  componentDidMount() {
    this.props.actionsOpened({ type: null });
  }

  componentWillUnmount() {
    this.props.actionsClosed({ type: null });
  }

  render() {
    const { t, theme, categoryActions } = this.props;

    return <div>{mapCategoryActionsToItems(categoryActions, t, theme)}</div>;
  }
}

CategoryActions.propTypes = {
  actionsOpened: PropTypes.func,
  actionsClosed: PropTypes.func,
  t: PropTypes.func,
  theme: PropTypes.string,
  categoryActions: PropTypes.array.isRequired,
};

const mapRuntimeToProps = (_state, _ownProps, actions) => {
  return {
    actionsOpened: actions.actionsOpened,
    actionsClosed: actions.actionsClosed,
  };
};

// prettier-ignore
export default flowRight(
  connect(mapRuntimeToProps),
  withTranslate,
  withCategoryActions,
)(CategoryActions);
