import React from 'react';
import PropTypes from 'prop-types';
import CardBackgroundContainer from '../container/card-background-container';

const ItemContainer = ({ children, ...props }) => (
  <CardBackgroundContainer {...props}>{children}</CardBackgroundContainer>
);

ItemContainer.propTypes = {
  children: PropTypes.any,
};

export default ItemContainer;
