import classNames from 'classnames';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import useIntersectionObserverApi from '../../services/use-intersection-observer-api';
import PostListItem from '../post-list-item';
import PostListItemPlaceholder from '../post-list-item-placeholder';
import styles from './post-list-item-virtualized.scss';

interface PostWithId {
  _id: string;
}

interface PostListItemVirtualizedProps {
  post: PostWithId;
  onLikeClick: () => void;
  category: any;
  showCategoryLink: boolean;
  type: string;
}

interface PostItemHeights {
  postHeight: number;
  postHeaderHeight: number;
  postHeaderPadding: number;
  postFooterHeight: number;
}

const getElementHeight = (element: Element | null): number => {
  if (!element) {
    return 0;
  }

  return element.getBoundingClientRect().height;
};

const getElementTopAndBottomPadding = (element: Element | null): number => {
  if (!element) {
    return 0;
  }

  const computedStyle = getComputedStyle(element);
  // /[^0-9]/g means anything except numbers
  const topPadding = Number(computedStyle.getPropertyValue('padding-top').replace(/[^0-9]/g, ''));
  const bottomPadding = Number(
    computedStyle.getPropertyValue('padding-bottom').replace(/[^0-9]/g, ''),
  );

  return topPadding + bottomPadding;
};

const PostListItemVirtualized = ({
  post,
  onLikeClick,
  category,
  showCategoryLink,
  type,
}: PostListItemVirtualizedProps) => {
  const postRef = useRef<HTMLDivElement | null>(null);
  const postStyleValues = useRef<PostItemHeights>({
    postHeight: 0,
    postHeaderHeight: 0,
    postHeaderPadding: 0,
    postFooterHeight: 0,
  });
  const [isLoadingImage, setIsLoadingImage] = useState<boolean>(true);
  const isVisible = useIntersectionObserverApi({
    elementRef: postRef,
  });

  const setHeights = () => {
    if (!postRef.current) {
      return;
    }
    const renderedElement = postRef.current.firstElementChild;
    const elementCurrentHeight = getElementHeight(postRef.current);
    if (
      elementCurrentHeight === postStyleValues.current.postHeight ||
      renderedElement?.getAttribute('data-hook') === 'post-list-item-placeholder'
    ) {
      return;
    }
    postStyleValues.current.postHeight = Math.max(
      postStyleValues.current.postHeight,
      elementCurrentHeight,
    );

    const headerElement = renderedElement?.firstElementChild ?? null;
    const footerElement = renderedElement?.lastElementChild ?? null;

    postStyleValues.current.postHeaderHeight = getElementHeight(headerElement);
    postStyleValues.current.postHeaderPadding = getElementTopAndBottomPadding(headerElement);
    postStyleValues.current.postFooterHeight = getElementHeight(footerElement);
  };

  useLayoutEffect(() => {
    setHeights();
  }, [isVisible, isLoadingImage]);

  useEffect(() => {
    if (!isLoadingImage || !postRef.current) {
      return;
    }
    const imgEl = postRef.current.querySelector('img');
    if (!imgEl || imgEl.complete) {
      setIsLoadingImage(false);
      return;
    }
    imgEl.onload = () => {
      setHeights();

      setIsLoadingImage(false);
    };
  }, [postRef, isLoadingImage]);

  const renderPost = () => (
    <PostListItem
      post={post}
      onLikeClick={onLikeClick}
      showCategoryLink={showCategoryLink}
      category={category}
      type={type}
      showRecentActivity
    />
  );

  const renderPlaceholder = () => (
    <PostListItemPlaceholder
      type={type}
      height={postStyleValues.current.postHeight}
      headerHeight={postStyleValues.current.postHeaderHeight}
      headerPadding={postStyleValues.current.postHeaderPadding}
      footerHeight={postStyleValues.current.postFooterHeight}
    />
  );

  return (
    <div
      className={classNames(styles.listItem, 'post-list__post-list-item')}
      id={post._id}
      style={{ minHeight: postStyleValues.current.postHeight }}
      ref={postRef}
    >
      {isVisible || !postStyleValues.current.postHeight || isLoadingImage
        ? renderPost()
        : renderPlaceholder()}
    </div>
  );
};

export default PostListItemVirtualized;
