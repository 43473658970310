export const MENTION_KEY = 'frocn';

export const createInitialDraftJsContentWithMention = ({ name, slug, id, prefix = '@' }) => ({
  blocks: [
    {
      key: MENTION_KEY,
      text: `${prefix}${name} `,
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [
        {
          offset: 0,
          length: prefix.length + name.length,
          key: 0,
        },
      ],
      data: {},
    },
  ],
  entityMap: {
    0: {
      type: 'mention',
      mutability: 'SEGMENTED',
      data: {
        mention: {
          name,
          slug,
          id,
          key: MENTION_KEY,
        },
      },
    },
  },
});
