import { FC, ReactNode } from 'react';
import * as React from 'react';
import { classes } from './navigation.st.css';
import classNames from 'classnames';

type NavigationItem = {
  id?: string;
  title?: string;
  postfix?: ReactNode;
};

type NavigationProps = {
  className: string;
  items: NavigationItem[];
  activeItemIndex: number;
  onItemClick: (index: number) => void;
};

export const Navigation: FC<NavigationProps> = ({
  className,
  items,
  activeItemIndex,
  onItemClick,
}) => {
  const onKeyDown = (e: React.KeyboardEvent<HTMLAnchorElement>, index: number) => {
    if (e.key === ' ' || e.key === 'Enter') {
      e.preventDefault();
      onItemClick(index);
    }
  };

  return (
    <nav className={className}>
      <ul className={classes.wrapper}>
        {items.map((item, index) => (
          <li>
            <a
              href="#"
              data-hook={`navigation-${item.id}`}
              id={item.id}
              onClick={e => {
                e.preventDefault();
                onItemClick(index);
              }}
              onKeyDown={e => onKeyDown(e, index)}
              className={classNames({
                [classes.item]: true,
                [classes.activeItem]: activeItemIndex === index,
              })}
            >
              {item.title}
              {item.postfix}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
};
