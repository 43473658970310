import React from 'react';

export const LockSlimIcon = ({ ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" {...props}>
    <defs>
      <path
        id="LockSlimIcon-a"
        d="M5.5.996a4.5 4.5 0 0 1 4.5 4.5V7h.002a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1h-9a1 1 0 0 1-1-1V8A1 1 0 0 1 1 7V5.496a4.5 4.5 0 0 1 4.5-4.5zM10 8H1v7h9V8zM5.5 2A3.5 3.5 0 0 0 2 5.5V7h7V5.5A3.5 3.5 0 0 0 5.5 2z"
      />
    </defs>
    <g fillRule="evenodd" transform="translate(4 1)">
      <mask id="LockSlimIcon-b">
        <use xlinkHref="#LockSlimIcon-a" />
      </mask>
      <use fillRule="nonzero" xlinkHref="#LockSlimIcon-a" />
      <g mask="url(#LockSlimIcon-b)">
        <path d="M-3 0h17v17H-3z" />
      </g>
    </g>
  </svg>
);
