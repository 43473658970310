import React, { Component } from 'react';
import Page from '../../components/page';
import { FullHeightLayout, SimpleLayout } from '../../components/layout';
import NotFound from '../../components/not-found';
import forDevice from '../../hoc/for-device';

const Layout = forDevice(FullHeightLayout, SimpleLayout);

export class NotFoundPage extends Component {
  render() {
    return (
      <Page>
        <Layout>
          <NotFound />
        </Layout>
      </Page>
    );
  }
}

export default NotFoundPage;
