import PropTypes from 'prop-types';
import React from 'react';
import styles from './large-spacing-layout.scss';

const LargeSpacingLayout = ({ spacingBetweenAll, children }) => (
  <div className={spacingBetweenAll ? styles.containerExtraSpacing : styles.container}>
    {children}
  </div>
);

LargeSpacingLayout.propTypes = {
  children: PropTypes.node,
  spacingBetweenAll: PropTypes.bool,
};

export default LargeSpacingLayout;
