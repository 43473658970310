import { flowRight } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withTranslate from '../../../common/components/with-translate/with-translate';
import { sortingToTranslations } from '../../services/sorting-to-translations';
import styles from './filters-bar-mobile.scss';
import withFontClassName from '../../hoc/with-font-class-name';
import { ArrowDown } from '../icons/arrow-down';
import { withPreviewClickBlocker } from '../../../common/components/click-blocker/with-preview-click-blocker';

const FiltersBarMobile = ({
  contentFontClassName,
  currentSort,
  preventClickInPreview,
  t,
  onClick,
}) => (
  <button
    className={classNames(styles.container, 'forum-text-color')}
    onClick={preventClickInPreview(onClick)}
    data-hook="filter-and-sort-button"
  >
    <span className={classNames(styles.filterText, contentFontClassName, 'forum-text-color')}>
      {currentSort.map(sort => t(sortingToTranslations[sort]) || sort).join(', ')}
    </span>
    <ArrowDown className={classNames('forum-icon-fill')} />
  </button>
);

FiltersBarMobile.propTypes = {
  currentSort: PropTypes.arrayOf(PropTypes.string),
  contentFontClassName: PropTypes.string,
  preventClickInPreview: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  t: PropTypes.func,
};

export default flowRight(
  withTranslate,
  withFontClassName,
  withPreviewClickBlocker,
)(FiltersBarMobile);
