import React from 'react';
import PropTypes from 'prop-types';
import Pagination from '../pagination';
import styles from './pagination-mobile.scss';
import { HorizontalSeparator } from '../separator';

const PaginationMobile = props => {
  const { entityCount, showPerPage, isTop } = props;
  const shouldShowPagination = entityCount > showPerPage;

  return (
    shouldShowPagination && (
      <React.Fragment>
        {!isTop && <HorizontalSeparator />}
        <Pagination
          {...props}
          className={styles.pagination}
          arrowClass={styles.arrow}
          innerClass={styles.page}
          activeClass={styles.active}
          changePageOnClick={false}
          dataHook={isTop ? 'pagination-top' : 'pagination-bottom'}
          isMobile
        />
        {isTop && <HorizontalSeparator />}
      </React.Fragment>
    )
  );
};

PaginationMobile.propTypes = {
  showPerPage: PropTypes.number,
  className: PropTypes.string,
};

export default PaginationMobile;
