import { PLUGINS } from '@wix/communities-forum-client-commons';

export const pluginTypeMap = {
  [PLUGINS.LINK]: 'LINK',
  [PLUGINS.HASHTAG]: 'wix-draft-plugin-hashtag',
  [PLUGINS.MENTIONS]: 'mention',
  [PLUGINS.TEXT_COLOR]: 'wix-rich-content-text-color',
  [PLUGINS.LINK_PREVIEW]: 'wix-draft-plugin-link-preview',
  [PLUGINS.TEXT_HIGHLIGHT]: 'wix-rich-content-text-highlight',
  [PLUGINS.IMAGE]: 'wix-draft-plugin-image',
  [PLUGINS.VIDEO]: 'wix-draft-plugin-video',
  [PLUGINS.FILE_UPLOAD]: 'wix-draft-plugin-file-upload',
  [PLUGINS.GIPHY]: 'wix-draft-plugin-giphy',
  [PLUGINS.EMOJI]: 'wix-draft-plugin-emoji',
  [PLUGINS.CODE_BLOCK]: 'code-block',
  [PLUGINS.COLLAPSIBLE_LIST]: 'wix-rich-content-plugin-collapsible-list',
  [PLUGINS.DIVIDER]: 'wix-draft-plugin-divider',
  [PLUGINS.HTML]: 'wix-draft-plugin-html',
  [PLUGINS.VERTICAL_EMBED]: 'wix-draft-plugin-vertical-embed',
  [PLUGINS.SPOILER]: 'wix-rich-content-plugin-spoiler',
  [PLUGINS.POLLS]: 'wix-draft-plugin-poll',
};

export const pluginOrder = [
  PLUGINS.IMAGE,
  PLUGINS.VIDEO,
  PLUGINS.FILE_UPLOAD,
  PLUGINS.GIPHY,
  PLUGINS.EMOJI,
  PLUGINS.CODE_BLOCK,
  PLUGINS.COLLAPSIBLE_LIST,
  PLUGINS.DIVIDER,
  PLUGINS.HTML,
  PLUGINS.VERTICAL_EMBED,
  PLUGINS.SPOILER,
  PLUGINS.POLLS,
  PLUGINS.LINK,
  PLUGINS.HASHTAG,
  PLUGINS.MENTIONS,
  PLUGINS.TEXT_COLOR,
  PLUGINS.LINK_PREVIEW,
  PLUGINS.TEXT_HIGHLIGHT,
];
