import PropTypes from 'prop-types';
import React from 'react';
import { CSSTransitionGroup } from 'react-transition-group';
import classNames from 'classnames';
import LoaderBox from '../loader-box';
import loaderStyles from './animated-loader.scss';
import styles from './animated-loader-container.scss';
import contentStyles from './animated-loader-content.scss';

const ANIMATION_TIMEOUT = 800;

const AnimatedLoader = ({ shouldShowLoader, isLoading, children, isAnimated, className }) => (
  <div className={classNames(styles.container, className)} data-hook="animated-loader__container">
    <CSSTransitionGroup
      component="div"
      transitionName={loaderStyles}
      transitionEnter={false}
      transitionLeaveTimeout={ANIMATION_TIMEOUT}
    >
      {shouldShowLoader && isLoading ? <LoaderBox key="loaderBox" /> : null}
    </CSSTransitionGroup>
    <CSSTransitionGroup
      component="div"
      transitionName={contentStyles}
      transitionEnter={isAnimated}
      transitionLeave={false}
      transitionEnterTimeout={ANIMATION_TIMEOUT}
    >
      {isLoading ? null : <div key="content">{children}</div>}
    </CSSTransitionGroup>
  </div>
);

AnimatedLoader.propTypes = {
  shouldShowLoader: PropTypes.bool,
  isLoading: PropTypes.bool,
  children: PropTypes.node,
  isAnimated: PropTypes.bool,
};

AnimatedLoader.defaultProps = {
  shouldShowLoader: true,
};

export default AnimatedLoader;
