import React from 'react';
import PropTypes from 'prop-types';
import { connect } from '../../../common/components/runtime-context';
import { getErrorState } from '../../../common/store/debug-state/debug-state-selectors';

const ErrorState = ({ errors }) => (
  <div>
    {errors.map(e => (
      <pre>{e}</pre>
    ))}
  </div>
);

ErrorState.propTypes = {
  errors: PropTypes.array,
};

const mapRuntimeToProps = state => ({
  errors: getErrorState(state),
});
export default connect(mapRuntimeToProps)(ErrorState);
