import React from 'react';

export const CloseIconTiny = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    stroke="none"
    {...props}
  >
    <path
      fill-rule="evenodd"
      d="M15.385 8l.615.615L12.615 12 16 15.385l-.615.615L12 12.615 8.615 16 8 15.385 11.384 12 8 8.615 8.615 8 12 11.385 15.385 8z"
    />
  </svg>
);
