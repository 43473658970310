import uploadFile from './upload-file';
import { TYPE_VIDEO } from './media-types';

export const uploadVideoToWixMedia = requestCredentialsPromisified => (file, folderId = null) => {
  const fileParams = {
    file_name: file.name,
    file_size: file.size,
    content_type: file.type,
  };

  return requestCredentialsPromisified(TYPE_VIDEO, fileParams).then(credentials => {
    return uploadFile({
      file,
      name: file.name,
      type: TYPE_VIDEO,
      credentials,
      folderId,
    });
  });

  /* response example
  [
    {
      "parent_folder_id": "media-root",
      "hash": "624a1faa2f52fd5d46c5355b1dd0dd76",
      "tags": [],
      "file_name": "a27d24_70b7158e00c649d199f951270014df12",
      "refs": [],
      "site_id": "c3b17772-224d-42fd-b7c1-6728938ba596",
      "height": 320,
      "original_file_name": "0-02-0a-0e9365ab54a9a15f6c662a774a84d5c14401262546224ffd28c6e93321c6591a_full.mp4",
      "modified_ts": 1574253397,
      "file_size": 2873720,
      "file_output": {
        "adaptive_video": [
          {
            "status": "READY",
            "url": "site/media/video/a27d24_70b7158e00c649d199f951270014df12/c3b17772-224d-42fd-b7c1-6728938ba596/repackage/hls",
            "tag": "HLS",
            "key": "hls.m3u8",
            "format": "hls"
          }
        ],
        "image": [
          {
            "status": "READY",
            "secure": false,
            "format": "jpg",
            "url": "media/a27d24_70b7158e00c649d199f951270014df12f000.jpg",
            "height": 568,
            "width": 320,
            "key": "poster.0"
          },
          {
            "status": "READY",
            "secure": false,
            "format": "jpg",
            "url": "media/a27d24_70b7158e00c649d199f951270014df12f001.jpg",
            "height": 568,
            "width": 320,
            "key": "poster.1"
          },
          {
            "status": "READY",
            "secure": false,
            "format": "jpg",
            "url": "media/a27d24_70b7158e00c649d199f951270014df12f002.jpg",
            "height": 568,
            "width": 320,
            "key": "poster.2"
          },
          {
            "status": "READY",
            "secure": false,
            "format": "jpg",
            "url": "media/a27d24_70b7158e00c649d199f951270014df12f003.jpg",
            "height": 568,
            "width": 320,
            "key": "poster.3"
          }
        ],
        "video": [
          {
            "status": "INPROGRESS",
            "secure": false,
            "fps": "21875/729",
            "format": "mp4",
            "url": "video/a27d24_70b7158e00c649d199f951270014df12/360p/mp4/file.mp4",
            "video_bitrate": 560000,
            "height": 568,
            "width": 320,
            "tag": "Low",
            "audio_bitrate": 64876,
            "key": "360p.mp4",
            "duration": 29160,
            "quality": "360p",
            "display_aspect_ratio": "40:71"
          }
        ]
      },
      "created_ts": 1574253397,
      "user_id": "a27d2491-90b0-4a3a-87d9-7a9de00bf17a",
      "icon_url": "media/a27d24_70b7158e00c649d199f951270014df12f002.jpg",
      "op_status": "IN-QUEUE",
      "file_url": "video/a27d24_70b7158e00c649d199f951270014df12/file",
      "width": 568,
      "store": "mp",
      "file_input": {
        "fps": "21875/729",
        "video_bitrate": 718971,
        "height": 320,
        "width": 568,
        "audio_bitrate": 64876,
        "sample_aspect_ratio": "1:1",
        "duration": 29160,
        "rotation": 90,
        "type": "video",
        "display_aspect_ratio": "71:40"
      },
      "media_type": "video",
      "mime_type": "video/mp4"
    }
  ]
  */
};
