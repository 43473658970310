import { cloneDeep, flattenDeep } from 'lodash';
import render from 'redraft';

const convertToString = nodes =>
  flattenDeep(nodes)
    .filter(value => [undefined, '', ' '].indexOf(value) === -1)
    .join(' ');

const renderer = children => children;

const inlineRenderers = {
  BOLD: renderer,
  ITALIC: renderer,
};

const blockRenderers = {
  unstyled: renderer,
  blockquote: renderer,
  'header-one': renderer,
  'header-two': renderer,
  'unordered-list-item': renderer,
  'ordered-list-item': renderer,
  'code-block': renderer,
};

const entityRenderers = {
  LINK: renderer,
};

export default function contentStateToString(contentState) {
  if (!contentState) {
    return '';
  }
  const contentStateCopy = cloneDeep(contentState);
  let content;

  // FIXME: Redraft does not render when the first block is unstyled & empty
  const firstBlock = contentStateCopy.blocks[0];
  if (firstBlock && firstBlock.type === 'unstyled' && !firstBlock.text) {
    firstBlock.text = ' ';
  }

  try {
    content = convertToString(
      render(contentStateCopy, {
        inline: inlineRenderers,
        blocks: blockRenderers,
        entities: entityRenderers,
      }),
    );
  } catch (e) {
    console.log('contentStateToString error', e);
  }
  return content || '';
}
