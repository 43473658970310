import React from 'react';

export const XSmall = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    style={{
      fillRule: 'evenodd',
    }}
    {...props}
  >
    <path d="M18.7692308,4 L20,5.23076923 L13.23,12 L20,18.7692308 L18.7692308,20 L12,13.23 L5.23076923,20 L4,18.7692308 L10.769,12 L4,5.23076923 L5.23076923,4 L12,10.769 L18.7692308,4 Z" />
  </svg>
);
