import getMainTheme from './theme';
import styles from './theme-post-form.scss';
import { mergeStyles } from './utils';

export default function getTheme(args) {
  return mergeStyles(getMainTheme(args), {
    editor: styles.editor,
    wrapper: styles.wrapper,
    desktop: styles.desktop,
    atomic: styles.atomic,
    footerToolbar: styles.footerToolbar,
    footerToolbar_buttons: styles.footerToolbar_buttons,
  });
}
