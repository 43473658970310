import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from '../../../common/components/runtime-context';
import classNames from 'classnames';
import Link from '../link/internal-link';
import MoreButton from '../more-button';
import CategoryActions from '../../containers/category-actions';
import withTranslate from '../../../common/components/with-translate/with-translate';
import withCardBorderWidth from '../../hoc/with-card-border-width';
import withFontClassName from '../../hoc/with-font-class-name';
import withAuth from '../../hoc/with-auth';
import styles from './subcategory-list-item-desktop.scss';
import FollowButton from '../follow-button';
import withPermissions from '../../hoc/with-permissions';
import { isPrivate, SORT_BY_NEWEST } from '@wix/communities-forum-client-commons';
import { LockEmptyIcon } from '../icons/lock-empty-icon';
import { CurrencySignIcon } from '../icons/currency-sign-icon';
import { hasCategoryPaidPlans } from '../../selectors/paid-plans-selectors';
import FollowNotification from '../follow-notification';
import { ViewCountIcon } from '../icons/view-count-icon';
import { CommentIconSmall } from '../icons/comment-icon-small';
import NewContentIndicator from '../new-content-indicator';
import {
  getLanguage,
  isCacheableRendering,
} from '../../../common/store/basic-params/basic-params-selectors';

import CounterNumber from '../counter-number';

export const SubcategoryListItemDesktop = props => {
  const {
    category,
    t,
    titleFontClassName,
    contentFontClassName,
    isViewsCountEnabled,
    fetchCategoryPosts,
    resetPostTypeFilter,
    can,
    hasCategoryPP,
    showViewCount,
    setPostsPageSorting,
  } = props;
  const shouldShowMoreButton = can('edit', 'category', category);
  const className = classNames(styles.container, 'subcategory-list-item');
  const categoryLink = `/${category.slug}`;

  const handlePostFetch = sort => () => {
    sort && setPostsPageSorting(sort, { dontReportBI: true });
    resetPostTypeFilter();
    fetchCategoryPosts({ categoryId: category._id, page: 1, sort });
  };

  const linkToPosts = (children, dataHook, sort) => (
    <Link
      className={classNames('forum-text-color', 'forum-link-hover-color', styles.postLink)}
      to={categoryLink}
      onMouseDown={handlePostFetch(sort)}
      data-hook={dataHook}
    >
      {children}
    </Link>
  );
  const moreButton = (
    <MoreButton size={MoreButton.SIZE_SMALL}>
      <CategoryActions hideFollowButton category={category} />
    </MoreButton>
  );
  const categoryActions = (
    <div className={styles.actionsContainer}>
      <FollowButton
        category={category}
        className={shouldShowMoreButton ? styles.followButton : ''}
        data-hook="subcategory-list-item__follow-button"
        size={FollowButton.SIZE_SMALL}
      >
        <FollowNotification isFollowing={category.isSubscribed} />
      </FollowButton>
      {shouldShowMoreButton && moreButton}
    </div>
  );
  const titleText = (
    <h2
      name={category.label}
      className={classNames(styles.title)}
      data-hook="subcategory-list-item__title"
    >
      {category.label}
    </h2>
  );
  const title = linkToPosts(titleText);

  let categoryBadge = null;

  if (isPrivate(category)) {
    const Icon = hasCategoryPP ? CurrencySignIcon : LockEmptyIcon;
    categoryBadge = (
      <span className={styles.categoryBadgeWrapper}>
        <Icon className="forum-icon-fill" />
      </span>
    );
  }
  return (
    <div
      className={classNames(className, contentFontClassName, 'forum-text-color')}
      data-hook="subcategory-list-item"
    >
      <div className={styles.titleWrapper}>
        <div className={classNames(titleFontClassName, styles.titleWithIcon)}>
          {title} {categoryBadge}
        </div>
        {categoryActions}
      </div>
      <div className={styles.statistics} data-hook="category-list-item__statistics">
        {isViewsCountEnabled && (
          <div
            className={styles.numberElementWithIcon}
            data-hook="subcategory-list-item__total-views"
          >
            <ViewCountIcon title={t('subcategory-list-item.views')} className="forum-icon-fill" />
            <span className={classNames(styles.numberElementText)}>
              {showViewCount ? (
                <CounterNumber entityId={category._id} viewCount />
              ) : (
                <span>&nbsp;</span>
              )}
            </span>
          </div>
        )}

        <div
          className={classNames(styles.numberElementWithIcon, styles.postsNumber)}
          data-hook="subcategory-list-item__total-posts"
        >
          <CommentIconSmall
            title={t('subcategory-list-item.posts', 'subcategories-list-item__total-posts')}
            className="forum-icon-fill"
          />
          <span className={classNames(styles.numberElementText)}>
            <CounterNumber initialValue={category.totalPosts} entityId={category._id} totalPosts />
          </span>
        </div>
        {linkToPosts(
          <NewContentIndicator count={category.unseenPostsCount} />,
          null,
          SORT_BY_NEWEST,
        )}
      </div>
    </div>
  );
};

SubcategoryListItemDesktop.propTypes = {
  category: PropTypes.object.isRequired,
  t: PropTypes.func,
  borderWidth: PropTypes.number,
  titleFontClassName: PropTypes.string,
  contentFontClassName: PropTypes.string,
  isAuthenticated: PropTypes.bool,
  isViewsCountEnabled: PropTypes.bool,
  fetchCategoryPosts: PropTypes.func,
  resetPostTypeFilter: PropTypes.func,
  setPostsPageSorting: PropTypes.func,
  hasCategoryPP: PropTypes.bool,
  can: PropTypes.func,
};

const mapRuntimeToProps = (state, { category }, actions) => ({
  fetchCategoryPosts: actions.fetchCategoryPosts,
  resetPostTypeFilter: actions.resetPostTypeFilter,
  setPostsPageSorting: actions.setPostsPageSorting,
  hasCategoryPP: hasCategoryPaidPlans(state, category.groups),
  showViewCount: !isCacheableRendering(state),
  language: getLanguage(state),
});

export default flowRight(
  connect(mapRuntimeToProps),
  withAuth,
  withCardBorderWidth,
  withFontClassName,
  withTranslate,
  withPermissions,
)(SubcategoryListItemDesktop);
