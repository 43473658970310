import { WebsiteHeaderSelectors } from '../constants/website-header-selectors';
import { getElementPropertyValueNumber } from './get-element-property-value';

export const getWebsiteFixedHeaderHeight = () => {
  const headerPlaceholder = document.querySelector(WebsiteHeaderSelectors.FixedHeaderPlaceholder);

  const fixedHeaderHeight = headerPlaceholder
    ? getElementPropertyValueNumber(headerPlaceholder, 'height')
    : 0;

  return fixedHeaderHeight;
};
