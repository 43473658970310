import React from 'react';
import forDirection from '../../hoc/for-direction';

const ArrowIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="6"
    height="10"
    viewBox="0 0 6 10"
    style={{ fillRule: 'evenodd' }}
    {...props}
  >
    <path
      d="M785.783,854.5l-4.552,4.3a0.771,0.771,0,0,1-1.048,0,0.672,0.672,0,0,1,0-.99L784.21,854l-4.027-3.8a0.672,0.672,0,0,1,0-.99,0.773,0.773,0,0,1,1.048,0l4.552,4.3A0.674,0.674,0,0,1,785.783,854.5Z"
      transform="translate(-780 -849.031)"
    />
  </svg>
);

const ArrowIconRtl = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="6"
    height="10"
    viewBox="0 0 6 10"
    style={{ fillRule: 'evenodd' }}
    {...props}
  >
    <g transform="translate(-100.000000, -43.000000)">
      <polygon
        transform="translate(102.980000, 48.000000) scale(-1, 1) translate(-102.980000, -48.000000) "
        points="100.837143 53 99.98 52.204793 104.514884 48 99.98 43.795207 100.837143 43 105.98 48"
      />
    </g>
  </svg>
);

export default forDirection(ArrowIcon, ArrowIconRtl);
