import React from 'react';
import styles from './pagination.scss';
import Pagination from './pagination.js';

export const PaginationClassic = props => (
  <Pagination
    className={styles.large}
    arrowClass={styles.arrow}
    innerClass={styles.inner}
    {...props}
  />
);
