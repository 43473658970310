import {
  SORT_BY_LAST_ACTIVITY,
  SORT_BY_NEWEST,
  SORT_BY_COMMENTS,
  SORT_BY_MOST_VIEWS,
  SORT_BY_LIKES,
  SORT_BY_BEST_MATCH,
} from '@wix/communities-forum-client-commons';

export const sortingToTranslations = {
  [SORT_BY_LAST_ACTIVITY]: 'post-sorting.sortByLastActivity',
  [SORT_BY_NEWEST]: 'post-sorting.newest',
  [SORT_BY_COMMENTS]: 'post-sorting.comments',
  [SORT_BY_MOST_VIEWS]: 'post-sorting.views',
  [SORT_BY_LIKES]: 'post-sorting.likes',
  [SORT_BY_BEST_MATCH]: 'post-sorting.bestMatch',
};
