import React from 'react';

export const ArrowDownIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11"
    height="6"
    viewBox="0 0 11 6"
    style={{
      fillRule: 'evenodd',
    }}
    {...props}
  >
    <path
      d="M846.007,697.852l-5.251,5.156-0.256-.252-0.256.252-5.252-5.156,0.876-.859,4.632,4.548,4.632-4.548Z"
      transform="translate(-835 -697)"
    />
  </svg>
);
