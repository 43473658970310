import { last } from 'lodash';
import { getElementsPositionToRootWindow } from './get-elements-position';
import { scrollBy } from './scroll-by';

export const scrollToPostCommentForm = () => {
  const commentForm = last(document.querySelectorAll('[data-hook="comment-form"]'));
  if (commentForm) {
    getElementsPositionToRootWindow(commentForm, { center: true }, y => {
      scrollBy(0, y);
      const rce = commentForm.querySelector('[contenteditable="true"]');
      if (rce) {
        rce.focus();
      }
    });
  }
};
