import React from 'react';
import classNames from 'classnames';
import SearchInput from '../search-input';

export const renderSearchInput = () => (
  <SearchInput
    inputClassName="desktop-header-search-text-color desktop-header-search-font"
    borderClassName="desktop-header-search-border-background-color"
    iconClassName={classNames('desktop-header-search-icon-fill', 'button-hover-fill')}
  />
);
