import React from 'react';
import PropTypes from 'prop-types';

const CardBackgroundContainer = ({ children }) => (
  <div className="forum-card-background-color">{children}</div>
);

CardBackgroundContainer.propTypes = {
  children: PropTypes.any,
};

export default CardBackgroundContainer;
