import PropTypes from 'prop-types';
import React from 'react';
import { range } from 'lodash';

const InfiniteList = ({ length, itemRenderer }) => <div>{range(length).map(itemRenderer)}</div>;

InfiniteList.propTypes = {
  length: PropTypes.number.isRequired,
  itemRenderer: PropTypes.func.isRequired,
};

export default InfiniteList;
