import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import styles from './image-loader.scss';

const ImageLoader = ({ className, type }) => (
  <div className={classNames(styles.loader, className, 'image-loader', { [styles[type]]: type })} />
);

ImageLoader.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
};

export default ImageLoader;
