import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from '../../../common/components/runtime-context';
import classNames from 'classnames';
import { ImgBackground } from '@wix/communities-image-lib';
import styles from './cover.scss';

const getWindowWidth = () => typeof window !== 'undefined' && window.innerWidth;

class Cover extends Component {
  constructor(props) {
    super(props);

    this.state = {
      width: props.width || getWindowWidth() || props.componentWidth,
      height: props.height,
    };
  }

  render() {
    const { width, height } = this.state;
    const { className, cover, showCover, children, hasActiveState } = this.props;
    const containerClassName = classNames(styles.cover, className, {
      [styles.hasActiveState]: hasActiveState,
    });
    const image = showCover && cover ? cover : '';

    return (
      <ImgBackground
        src={image}
        className={containerClassName}
        width={width}
        height={height}
        alt=""
      >
        {children}
      </ImgBackground>
    );
  }
}

Cover.propTypes = {
  className: PropTypes.string,
  cover: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  showCover: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number.isRequired,
  children: PropTypes.node,
  hasActiveState: PropTypes.bool,
  componentWidth: PropTypes.number,
};

Cover.defaultProps = {
  showCover: true,
  hasActiveState: true,
};

const mapRuntimeToProps = (state, ownProps, actions, host) => ({
  componentWidth: host.dimensions.width || undefined,
});

export default connect(mapRuntimeToProps)(Cover);
