import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import TimeAgo from '../time-ago';
import { BulletSeparator } from '../separator';
import MemberCardAsync from '../../containers/member-card-async';
import ProfileLink from '../link/profile-link';
import RoleBadge from '../role-badge';
import QuickUserInfo from '../quick-user-info';
import styles from './card-header-left-side.scss';

class CardHeaderLeftSide extends Component {
  render() {
    const { user = {}, date } = this.props;

    return (
      <div className={styles.headerLeftSide}>
        <div className={styles.headerTop}>
          <MemberCardAsync
            viewedMemberId={user.siteMemberId}
            viewedMemberSlug={user.slug}
            className={styles.linkWrapper}
          >
            <ProfileLink user={user} className={styles.link}>
              <span
                className={classNames(styles.name, 'forum-text-color', 'forum-link-hover-color')}
                data-hook="avatar__name"
              >
                {user?.name}
              </span>
            </ProfileLink>
          </MemberCardAsync>
          <RoleBadge user={user} className={classNames('forum-icon-fill')} />
          <BulletSeparator className={styles.bulletSeparator} />
          <TimeAgo className={classNames(styles.timeAgo, 'forum-text-color')} time={date} />
        </div>
        <QuickUserInfo
          className={styles.badge}
          user={user}
          showBadges
          truncateBadgeTitle
          noMargin
        />
      </div>
    );
  }
}

CardHeaderLeftSide.propTypes = {
  user: PropTypes.object.isRequired,
  date: PropTypes.string.isRequired,
};

export default CardHeaderLeftSide;
