import { DISCUSSION, QUESTION } from '@wix/communities-forum-client-commons';
import { isString } from 'lodash';
import ArrowDownRight from 'wix-ui-icons-common/ArrowDownRight';
import React from 'react';

const subtitleMap = {
  [DISCUSSION]: 'category-dropdown-select.discussions',
  [QUESTION]: 'category-dropdown-select.questions',
  'selected-members': 'category-dropdown-select.selected-members',
};

export const getOptionsFromCategories = ({ t, categories, can }) => {
  return categories.map(category => {
    const canPost = can('create-post', 'category', category);
    const isDisabled = !canPost;
    const subtitle = canPost
      ? category.postTypes.includes(DISCUSSION)
        ? subtitleMap[DISCUSSION]
        : subtitleMap[QUESTION]
      : subtitleMap['selected-members'];

    return {
      id: category._id,
      value: category.label,
      subtitle: isDisabled ? t(subtitle) : undefined,
      icon: isString(category.parentId) ? <ArrowDownRight /> : undefined,
      isSelectable: !isDisabled,
      indentIcon: true,
    };
  });
};
