import PropTypes from 'prop-types';
import React from 'react';
import { connect } from '../../../common/components/runtime-context';
import { MainPageDescription } from '../page-description';
import { getHeaderTitle } from '../../services/categories-service';
import { getForumData } from '../../selectors/forum-data-selectors';
import { getIsMainPageHeaderEnabled } from '../../selectors/app-settings-selectors';
import { REDUCERS } from '../../../common/components/runtime-context/reducers';

const ForumDescription = ({ forumData, isCoverVisible }) => {
  return (
    <MainPageDescription
      title={getHeaderTitle(forumData)}
      description={forumData.description}
      cover={forumData.cover}
      coverType={forumData.coverType}
      overlayColor={forumData.overlayColor}
      backgroundColor={forumData.backgroundColor}
      textColor={forumData.textColor}
      isCoverVisible={isCoverVisible}
    />
  );
};

ForumDescription.propTypes = {
  forumData: PropTypes.object.isRequired,
  isCoverVisible: PropTypes.bool.isRequired,
};

const mapRuntimeToProps = (state, ownProps, actions, host) => ({
  forumData: getForumData(state),
  isCoverVisible: getIsMainPageHeaderEnabled(state, host.style),
});

export default connect(mapRuntimeToProps, [REDUCERS.FORUM_DATA])(ForumDescription);
