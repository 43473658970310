import PropTypes from 'prop-types';
import React from 'react';

export const LikeHollowIcon = ({ title, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    viewBox="0 0 17 15"
    fill="none"
    style={{
      fillRule: 'evenodd',
    }}
    role="img"
    {...props}
  >
    <title>{title}</title>
    <path
      d="M350,217.365a4.312,4.312,0,0,0-8-2.28,4.309,4.309,0,0,0-8,2.28,4.375,4.375,0,0,0,1.487,3.286l6.12,6.184A0.567,0.567,0,0,0,342,227a0.553,0.553,0,0,0,.4-0.165l6.12-6.184A4.375,4.375,0,0,0,350,217.365Z"
      transform="translate(-333.5 -212.5)"
    />
  </svg>
);

LikeHollowIcon.propTypes = {
  title: PropTypes.string,
};
