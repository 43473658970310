import { reduce } from 'lodash';
import { TinyColor } from '@ctrl/tinycolor';
import { isHexColor } from 'wix-rich-content-common';
import {
  LINKS_HASHTAGS_COLOR,
  APP_TEXT_COLOR,
  getPresetColor,
  getSiteColor,
} from '@wix/communities-forum-client-commons';

export const getTextColorSchema = (style = {}) => {
  const colors = [
    getSiteColor(style, {
      wixParam: APP_TEXT_COLOR,
      presetFallback: 'color-5',
      colorFallback: '#00000',
    }),
    getSiteColor(style, {
      wixParam: LINKS_HASHTAGS_COLOR,
      presetFallback: 'color-8',
      colorFallback: '#00000',
    }),
    getPresetColor(style.siteColors, 'color-13'),
    getPresetColor(style.siteColors, 'color-18'),
    getPresetColor(style.siteColors, 'color-23'),
  ];

  return reduce(
    colors,
    (schema, color, index) => {
      schema[`textColor${index}`] = { color: toHexColor(color), index };
      return schema;
    },
    {},
  );
};

const toHexColor = color => new TinyColor(color).toHexString();

export const styleSelectionPredicate = colorScheme => inlineStyle =>
  (colorScheme[inlineStyle] && isHexColor(colorScheme[inlineStyle].color)) ||
  isHexColor(inlineStyle);

export const viewerCustomStyleFn = (colorScheme, isHighlight) => inlineStyle => {
  const param = isHighlight ? 'background-color' : 'color';
  if (colorScheme[inlineStyle] && isHexColor(colorScheme[inlineStyle].color)) {
    return { [param]: colorScheme[inlineStyle].color };
  }

  if (isHexColor(inlineStyle)) {
    return { [param]: inlineStyle };
  }

  return {};
};

export const customStyleFn = viewerCustomStyleFn => inlineStyles =>
  reduce(
    inlineStyles.toArray(),
    (styles, inlineStyle) => ({
      ...styles,
      ...viewerCustomStyleFn(inlineStyle),
    }),
    {},
  );
