import React from 'react';
import PropTypes from 'prop-types';
import DesktopSelect from '../sorting-select/desktop-select';
import SortingSelectHeadless from './sorting-select-headless';

const SortingSelectDesktop = ({ query }) => (
  <SortingSelectHeadless query={query}>
    {({ value, options, onChange }) => (
      <DesktopSelect options={options} value={value} onChange={onChange} />
    )}
  </SortingSelectHeadless>
);

SortingSelectDesktop.propTypes = {
  isInSearchPage: PropTypes.bool,
  query: PropTypes.string,
};

export default SortingSelectDesktop;
