import React, { useEffect, useState } from 'react';

interface IntersectionObserverAPIProps {
  elementRef: React.MutableRefObject<HTMLDivElement | null>;
  rootMargin?: string;
  threshold?: number | number[];
}

const useIntersectionObserverApi = ({
  elementRef,
  rootMargin,
  threshold,
}: IntersectionObserverAPIProps) => {
  const [isVisible, setIsVisible] = useState<boolean>(true);
  useEffect(() => {
    const refCopy = { ...elementRef };
    if (!refCopy.current) {
      return;
    }
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting !== isVisible) {
          setIsVisible(entries[0].isIntersecting);
        }
      },
      {
        rootMargin: rootMargin ? rootMargin : '50%',
        threshold: threshold ? threshold : [0.01, 0.25, 0.5, 0.75, 1],
      },
    );

    observer.observe(refCopy.current);
    return () => {
      if (refCopy?.current) {
        observer.unobserve(refCopy.current);
      }
    };
  }, [elementRef, rootMargin, isVisible, threshold]);

  return isVisible;
};

export default useIntersectionObserverApi;
