const WIDTH = 640;
const HEIGHT = 640;
const openShareDialog = url => {
  window.open(
    url,
    'Share',
    `width=${WIDTH},height=${HEIGHT},toolbar=no,menubar=no,scrollbars=no,location=no,status=no`,
  );
};

const getUrl = (socialNetwork, href) => {
  switch (socialNetwork) {
    case 'facebook':
      return `http://www.facebook.com/sharer/sharer.php?u=${href}`;
    case 'twitter':
      return `https://twitter.com/share?url=${href}`;
    default:
      return;
  }
};

export default function share(type, path) {
  openShareDialog(getUrl(type, path));
}
