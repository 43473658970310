import React from 'react';
import Page from '../../components/page';
import { FullHeightLayout, SimpleLayout } from '../../components/layout';
import AccountSuspended from '../../components/account-suspended';
import forDevice from '../../hoc/for-device';

const Layout = forDevice(FullHeightLayout, SimpleLayout);

const AccountSuspendedPage = () => (
  <Page>
    <Layout>
      <AccountSuspended />
    </Layout>
  </Page>
);

export default AccountSuspendedPage;
