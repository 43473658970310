import React from 'react';

export const ChevronUpDownIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path
      fill-rule="evenodd"
      d="M8.146 14.344c.195-.2.512-.2.708 0l3.145 3.208 3.147-3.209c.196-.2.513-.2.709.002.194.2.193.523-.002.722L12 19l-3.853-3.933c-.195-.2-.195-.523 0-.723zM12 5l3.854 3.933c.195.199.196.522.002.722-.196.201-.513.202-.709.002L12 6.448 8.854 9.657c-.196.2-.513.2-.708 0-.195-.2-.195-.524 0-.724L12 5z"
    />
  </svg>
);
