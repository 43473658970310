import React from 'react';

export const LockEmptyIcon = props => (
  <svg
    data-hook="lock-empty-icon"
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="19"
    viewBox="0 0 19 19"
    {...props}
  >
    <path
      id="a"
      d="M14 8h.002a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1h-9a1 1 0 0 1-1-1V9A1 1 0 0 1 5 8V6.496a4.5 4.5 0 0 1 9 0V8zM5 9v7h9V9H5zm4.5-6A3.5 3.5 0 0 0 6 6.5V8h7V6.5A3.5 3.5 0 0 0 9.5 3z"
    />
  </svg>
);
