import React from 'react';
import forDirection from '../../hoc/for-direction';

export const ArrowBackIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill-rule="evenodd"
      d="M6.707 13l4.148 4.148c.195.195.195.512 0 .707-.195.196-.512.196-.707 0L4.793 12.5l5.355-5.347c.195-.195.512-.195.707 0 .195.196.195.512 0 .707L6.707 12H19v1H6.707z"
    />
  </svg>
);

export const ArrowBackIconRtl = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="19" viewBox="0 0 19 19" role="img" {...props}>
    <path
      d="M5.96695729,9 L14.8828427,9 C14.9932997,9 15.0828427,9.08954305 15.0828427,9.2 L15.0828427,9.8 C15.0828427,9.91045695 14.9932997,10 14.8828427,10 L5.9031385,10 L8.854916,12.9517775 C8.93361354,13.030475 8.93409401,13.1575885 8.85598915,13.2356934 L8.43172508,13.6599574 C8.35362022,13.7380623 8.22650676,13.7375818 8.14780922,13.6588843 L4.15796309,9.66903816 C4.11861432,9.62968939 4.09881982,9.57823664 4.09862562,9.52685797 C4.09843142,9.47547931 4.11783752,9.42417473 4.15688994,9.3851223 L8.11668792,5.42532432 C8.19479278,5.34721946 8.32190624,5.34769993 8.40060378,5.42639747 L8.82808729,5.85388098 C8.90678484,5.93257853 8.9072653,6.05969199 8.82916044,6.13779685 L5.96695729,9 Z"
      transform="translate(9.582843, 9.542641) scale(-1, 1) translate(-9.582843, -9.542641) "
    />
  </svg>
);

export default forDirection(ArrowBackIcon, ArrowBackIconRtl);
