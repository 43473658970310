export const getActivePage = (oldState, newState) => {
  const oldPagesNumbers = oldState.map(({ pageNumber }) => pageNumber);
  const stateDiff = newState.filter(({ pageNumber }) => !oldPagesNumbers.includes(pageNumber));

  return [stateDiff.length > 0, stateDiff[0]];
};

export const parsePagerStateItemsUrl = (items = [], categorySlug) => {
  if (!categorySlug) {
    return items;
  }

  const regex = new RegExp(`.+?(?=/${categorySlug})`);

  return items.map(item => ({
    ...item,
    url: item.url.replace(regex, ''),
  }));
};
