import React from 'react';

export const SolvedIconInversed = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" {...props}>
    <g fill-rule="evenodd" transform="translate(0 -1)">
      <path d="M10,3 C14.418278,3 18,6.581722 18,11 C18,15.418278 14.418278,19 10,19 C5.581722,19 2,15.418278 2,11 C2,6.581722 5.581722,3 10,3 Z M10,4 C6.13400675,4 3,7.13400675 3,11 C3,14.8659932 6.13400675,18 10,18 C13.8659932,18 17,14.8659932 17,11 C17,7.13400675 13.8659932,4 10,4 Z" />
      <path d="M13.8568542,8.25735931 L14.563961,8.96446609 L9.61767966,13.9103593 L9.62132034,13.9142136 L8.91421356,14.6213203 L8.91067966,14.6173593 L8.90710678,14.6213203 L8.2,13.9142136 L8.20367966,13.9103593 L5.37867966,11.0857864 L6.08578644,10.3786797 L8.91067966,13.2033593 L13.8568542,8.25735931 Z" />
    </g>
  </svg>
);
