import React from 'react';
import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withCardBorderWidth from '../../hoc/with-card-border-width';
import styles from './post-list-item-placeholder.scss';

export const PostListItemPlaceholder = ({
  type,
  borderWidth,
  contentFontClassName,
  height,
  headerHeight,
  headerPadding,
  footerHeight,
}) => {
  const className = classNames(
    styles.container,
    styles[type],
    contentFontClassName,
    'forum-card-background-color',
    'forum-card-border-color',
    'forum-card-border-width',
    'post-list-item',
  );

  const contentSkeletonHeight = height - headerHeight - footerHeight;

  return (
    <div
      className={className}
      style={{
        borderWidth,
        height,
      }}
      data-hook="post-list-item-placeholder"
    >
      <div
        className={classNames(styles.skeleton, styles.postHeader)}
        style={{ height: headerHeight - headerPadding + 'px' }}
      />
      <div
        className={classNames(styles.skeleton, styles.postContent)}
        style={{
          height: contentSkeletonHeight + 'px',
        }}
      />
      <div className={classNames(styles.skeleton, styles.postFooterContent)} />
      <div className={classNames(styles.skeleton, styles.postRecentActivity)} />
    </div>
  );
};

PostListItemPlaceholder.propTypes = {
  type: PropTypes.string,
  borderWidth: PropTypes.number,
  height: PropTypes.number.isRequired,
  headerHeight: PropTypes.number.isRequired,
  headerPadding: PropTypes.number.isRequired,
  footerHeight: PropTypes.number.isRequired,
};

export default flowRight(withCardBorderWidth)(PostListItemPlaceholder);
