import React from 'react';
import Header from '../../components/header';
import HeaderButtons from '../../components/header-buttons';
import HeaderTitle from '../../components/header-title';
import PropTypes from 'prop-types';
import { flowRight } from 'lodash';
import withExperiment from '../../hoc/with-experiment';
import { EXPERIMENT_FORUM_TABS } from '@wix/communities-forum-client-commons';
import HeaderTabs from '../header-navigation/header-navigation.component';

const CategoriesPageHeader = ({ params, isForumTabsEnabled }) => (
  <Header
    left={isForumTabsEnabled ? <HeaderTabs /> : <HeaderTitle />}
    right={<HeaderButtons params={params} />}
  />
);

CategoriesPageHeader.propTypes = {
  params: PropTypes.object.isRequired,
  isForumTabsEnabled: PropTypes.bool.isRequired,
};

export default flowRight(
  withExperiment({
    isForumTabsEnabled: EXPERIMENT_FORUM_TABS,
  }),
)(CategoriesPageHeader);
