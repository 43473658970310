import React from 'react';

export const FacebookIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8.12"
    height="16"
    viewBox="0 0 8.12 16"
    style={{
      fillRule: 'evenodd',
    }}
    {...props}
  >
    <path
      d="M2535.93,890.5v-7h-2v-3h2v-2.527c0-1.815,1.41-3.481,4.12-3.481a20.191,20.191,0,0,1,2.02.105v2.524s-0.97-.008-1.87-0.008a1.062,1.062,0,0,0-1.27,1.2V880.5h3v3h-3v7h-3Z"
      transform="translate(-2533.94 -874.5)"
    />
  </svg>
);
