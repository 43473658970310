import React from 'react';
import classNames from 'classnames';
import styles from './mark-all-as-read-button.scss';
import { EXPERIMENT_RECENT_ACTIVITY_CATS } from '@wix/communities-forum-client-commons';
import { CheckMarkIcon } from '../icons/check-mark-icon';
import Button from '../button/button';
import { useTranslate } from '../../../common/api-providers/use-translate';
import { useApi } from '../../../common/api-providers/use-api';
import { isExperimentEnabled } from '../../selectors/experiments-selectors';

type MarkAllAsReadButtonProps = {
  className: string;
  category: { _id: string };
};

const MarkAllAsReadButton: React.FC<MarkAllAsReadButtonProps> = ({ className, category }) => {
  const { t } = useTranslate();
  const { markPostsAsRead, buttonClicked, isRecentActivityCatsEnabled } = useApi(
    (state, actions) => ({
      markPostsAsRead: actions.markPostsAsRead,
      buttonClicked: actions.buttonClicked,
      isRecentActivityCatsEnabled: isExperimentEnabled(state, EXPERIMENT_RECENT_ACTIVITY_CATS),
    }),
  );

  if (!isRecentActivityCatsEnabled) {
    return null;
  }

  const handleClick = () => {
    markPostsAsRead(category._id);
    buttonClicked({
      name: 'action_click',
      action: 'mark_all_as_read',
      categoryId: category._id,
    });
  };

  return (
    <Button
      isSecondary
      onClick={handleClick}
      isSmall
      data-hook="quick-best-answer"
      className={className}
    >
      <CheckMarkIcon className={classNames(styles.buttonIcon, 'button-fill')} />
      {t('recent-activity.mark-all-as-read')}
    </Button>
  );
};

export default MarkAllAsReadButton;
