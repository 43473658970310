import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import CategoryListItemDesktop from '../category-list-item-desktop';
import Masonry from '../../../common/components/masonry';
import styles from './category-list-desktop.scss';

const CategoryListDesktop = ({ className, categories, isViewsCountEnabled }) => (
  <div className={styles.container}>
    <Masonry
      columnClassName={styles.masonryColumn}
      containerWidth={960}
      className={classNames(className, styles.masonry)}
      breakpointCols={{ default: 2 }}
      data-hook="category-list-masonry"
    >
      {categories.map(category => (
        <div className={styles.listItem} key={category._id}>
          <CategoryListItemDesktop isViewsCountEnabled={isViewsCountEnabled} category={category} />
        </div>
      ))}
    </Masonry>
  </div>
);

CategoryListDesktop.propTypes = {
  className: PropTypes.string,
  categories: PropTypes.array.isRequired,
  isViewsCountEnabled: PropTypes.bool.isRequired,
};

export default CategoryListDesktop;
