import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { connect } from '../../../common/components/runtime-context';
import { getPreviousMatches, getCurrentMatch } from '../../../common/router/router-selectors';
import PageCover from '../page-cover';
import { COVER_TYPE_COLOR } from '../../constants/cover-types';
import { TEXT_ALIGNMENT_CLASS_NAMES, getPresetColor } from '@wix/communities-forum-client-commons';
import forDevice from '../../hoc/for-device';
import styles from './page-description.scss';
import {
  getHeaderHeight,
  getMainPageHeaderHeight,
  getCategoryTextAlignment,
  getMainPageHeaderTextAlignment,
} from '../../selectors/app-settings-selectors';

const DEFAULT_HEIGHT = 200;
const isEditorX = typeof window !== 'undefined' && window?.commonConfig?.brand === 'editorx';

const getContainerStyle = (textColor = {}, headerHeight) => {
  const style = { color: textColor.color };
  if (headerHeight) {
    style.height = headerHeight;
  }
  return style;
};

export class PageDescription extends React.Component {
  constructor(props) {
    super(props);
    this.headerRef = React.createRef();
    this.wrapperRef = React.createRef();
    this.descriptionRef = React.createRef();
    this.state = { clamp: null };
  }

  componentDidMount() {
    const { focusHeaderOnMount, routerHistory, currentRoute } = this.props;
    if (routerHistory && currentRoute) {
      const isFirstLoad = routerHistory.length === 1;
      // second condition is valid when forum has only one category and was redirected to it
      const isHomePage = currentRoute.pathname === '/' || currentRoute.prevMatches[0];

      if (focusHeaderOnMount && this.headerRef.current && !(isFirstLoad && isHomePage)) {
        this.headerRef.current.focus({ preventScroll: true });
      }
    }
    this.descriptionRef.current && this.updateDescriptionSizes();
  }

  componentDidUpdate(lastProps) {
    if (
      this.props.focusHeaderOnMount &&
      this.props.title !== lastProps.title &&
      this.headerRef.current
    ) {
      this.headerRef.current.focus();
    }
    this.canUpdateClamp() && this.updateClamp();
  }

  updateDescriptionSizes = () => {
    const { lineHeight, marginTop } =
      typeof window !== 'undefined' && window.getComputedStyle(this.descriptionRef.current);
    this.setState(
      {
        lineHeight: parseInt(lineHeight, 10),
        marginTop: parseInt(marginTop, 10),
      },
      () => this.canUpdateClamp() && this.updateClamp(),
    );
  };

  canUpdateClamp = () =>
    typeof window !== 'undefined' &&
    this.wrapperRef.current &&
    this.headerRef.current &&
    this.state.lineHeight;

  updateClamp = () => {
    const { paddingTop, paddingBottom } = window.getComputedStyle(this.wrapperRef.current);
    const { lineHeight, marginTop } = this.state;
    const wrapperHeight =
      this.wrapperRef.current.offsetHeight - parseInt(paddingTop, 10) - parseInt(paddingBottom, 10);
    const clamp = Math.floor(
      (wrapperHeight - this.headerRef.current.offsetHeight - marginTop) / lineHeight,
    );
    if (clamp !== this.state.clamp) {
      this.setState({
        clamp,
      });
    }
  };

  render() {
    const {
      title,
      description,
      cover,
      coverType,
      overlayColor = this.props.defaultEditorXOverlay,
      backgroundColor = this.props.defaultEditorXBackground,
      textColor = {},
      categoryTextAlignment,
      children,
      isCoverVisible,
      headerHeight,
    } = this.props;

    const { clamp } = this.state;

    const textAlignmentClassName = styles[TEXT_ALIGNMENT_CLASS_NAMES[categoryTextAlignment]];
    const containerClassName = classNames(
      styles.container,
      isEditorX ? 'default-desktop-header-text-color-editorx' : 'default-desktop-header-text-color',
      'page-description',
      {
        [textAlignmentClassName]: textAlignmentClassName,
      },
      styles.noMargin,
    );

    const NoCover = ({ children, className }) => <div className={className}>{children}</div>;

    const CoverComponent = isCoverVisible ? PageCover : NoCover;
    // if there is no image, any text and no background color, it would show empty white, block, so we hide it
    const hasNoContent =
      title === '' && description === '' && coverType === COVER_TYPE_COLOR && !backgroundColor;

    if (!isCoverVisible || hasNoContent) {
      return null;
    }

    return (
      <div className={containerClassName} style={getContainerStyle(textColor, headerHeight)}>
        <CoverComponent
          cover={cover}
          coverType={coverType}
          overlayColor={overlayColor}
          backgroundColor={backgroundColor}
          height={DEFAULT_HEIGHT}
          hasActiveState={false}
        >
          <div className={styles.wrapper} ref={this.wrapperRef}>
            <div
              className={classNames(
                styles.titleWrapper,
                'page-description-title-font',
                'page-description__title',
              )}
              data-hook="page-description__title"
            >
              <h1 tabIndex="-1" ref={this.headerRef} className={styles.title}>
                {title}
              </h1>
            </div>
            {clamp !== 0 ? (
              <div
                className={classNames(styles.descriptionWrapper, 'page-description-font')}
                data-hook="page-description__description"
                ref={this.descriptionRef}
              >
                <p
                  className={styles.description}
                  style={clamp ? { WebkitLineClamp: clamp, MozLineClamp: clamp } : {}}
                >
                  {description}
                </p>
              </div>
            ) : null}
            {children}
          </div>
        </CoverComponent>
      </div>
    );
  }
}

PageDescription.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  cover: PropTypes.object,
  coverType: PropTypes.string,
  overlayColor: PropTypes.object,
  backgroundColor: PropTypes.object,
  textColor: PropTypes.object,
  categoryTextAlignment: PropTypes.number.isRequired,
  children: PropTypes.node,
  isCoverVisible: PropTypes.bool.isRequired,
  headerHeight: PropTypes.number,
  focusHeaderOnMount: PropTypes.bool,
  type: PropTypes.string,
  defaultEditorXOverlay: PropTypes.string,
  defaultEditorXBackground: PropTypes.string,
};

PageDescription.defaultProps = {
  isCoverVisible: true,
};

const mapRuntimeToProps = (state, ownProps, actions, host) => {
  const color = getPresetColor(host.style.siteColors, 'color-5');
  return {
    headerHeight: getHeaderHeight(state, host.style),
    categoryTextAlignment: getCategoryTextAlignment(state, host.style),
    routerHistory: getPreviousMatches(state),
    currentRoute: getCurrentMatch(state),
    defaultEditorXOverlay: isEditorX && {
      color,
      opacity: 0.45,
    },
    defaultEditorXBackground: isEditorX && {
      color,
      opacity: 1,
    },
  };
};

export const PageDescriptionMobile = flowRight(connect(mapRuntimeToProps))(PageDescription);

export const PageDescriptionDesktop = flowRight(connect(mapRuntimeToProps))(PageDescription);

export default forDevice(PageDescriptionMobile, PageDescriptionDesktop);

const mapRuntimeToPropsMain = (state, ownProps, actions, host) => {
  const color = getPresetColor(host.style.siteColors, 'color-5');
  return {
    headerHeight: getMainPageHeaderHeight(state, host.style),
    categoryTextAlignment: getMainPageHeaderTextAlignment(state, host.style),
    routerHistory: getPreviousMatches(state),
    currentRoute: getCurrentMatch(state),
    defaultEditorXOverlay: isEditorX && {
      color,
      opacity: 0.45,
    },
    defaultEditorXBackground: isEditorX && {
      color,
      opacity: 1,
    },
  };
};

export const MainPageDescriptionMobile = flowRight(connect(mapRuntimeToPropsMain))(PageDescription);

export const MainPageDescriptionDesktop = flowRight(connect(mapRuntimeToPropsMain))(
  PageDescription,
);

export const MainPageDescription = forDevice(MainPageDescriptionMobile, MainPageDescriptionDesktop);
