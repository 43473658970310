import { getRepliesToCommentByPostId } from '../../selectors/comment-selectors';
import { getEntity, getEntityCount } from '../../selectors/pagination-selectors';
import { REPLIES_PER_PAGE } from '../../constants/pagination';

export const getHasMoreReplies = (state, ownProps) => {
  let hasMoreReplies;
  let nextRepliesPage;
  let nextRepliesCount;
  const replies = getRepliesToCommentByPostId(state, ownProps.comment.postId, ownProps.comment._id);
  if (replies.length > 0) {
    const replyEntities = getEntity(state, 'comments', ownProps.comment._id);
    const replyEntityCount = getEntityCount(state, 'comments', ownProps.comment._id);
    if (replyEntities) {
      hasMoreReplies = replyEntities.entityCount > replies.length;
      if (hasMoreReplies) {
        nextRepliesPage = Math.floor(replies.length / REPLIES_PER_PAGE) + 1;
        nextRepliesCount = replyEntityCount - (nextRepliesPage - 1) * REPLIES_PER_PAGE;
      }
    }
  }

  return { hasMoreReplies, nextRepliesPage, nextRepliesCount, replies };
};
