import { flowRight, get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from '../../../common/components/runtime-context';
import { REDUCERS } from '../../../common/components/runtime-context/reducers';
import AnimatedLoader from '../../components/animated-loader';
import withDeviceType from '../../hoc/with-device-type';
import Page from '../../components/page';
import NoSearchResults from '../../components/no-search-results';
import DefaultDesktopLayout from '../../components/default-desktop-layout';
import SearchResultsList from '../search-results-list';
import { getIsSearchLoading } from '../../selectors/is-loading-selectors';
import SearchPage from '../search-page';
import { getRouteParams } from '../../../common/router/router-selectors';
import { getSearchResult } from '../../selectors/search-selectors';
import { decodeQuery } from '../../services/query-encoding';

const ResultsPage = ({ result, isLoading, isMobile, query }) => {
  const hasResults = Boolean(get(result, 'length', 0));

  const withAnimatedLoader = children => {
    return (
      <AnimatedLoader isLoading={isLoading} isAnimated>
        {children}
      </AnimatedLoader>
    );
  };

  const renderResults = () => {
    return hasResults ? (
      <SearchResultsList posts={result} query={query} />
    ) : (
      withAnimatedLoader(<NoSearchResults />)
    );
  };
  return (
    <Page noSpacing={isMobile ? true : !hasResults}>
      <DefaultDesktopLayout>
        {query ? renderResults() : withAnimatedLoader(<SearchPage />)}
      </DefaultDesktopLayout>
    </Page>
  );
};

ResultsPage.propTypes = {
  result: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  query: PropTypes.string,
};

const mapRuntimeToProps = state => ({
  result: getSearchResult(state),
  isLoading: getIsSearchLoading(state),
  query: decodeQuery(getRouteParams(state).query),
});

export default flowRight(
  connect(mapRuntimeToProps, [REDUCERS.IS_LOADING, REDUCERS.ROUTER, REDUCERS.SEARCH]),
  withDeviceType,
)(ResultsPage);
