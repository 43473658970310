import { flowRight, throttle } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import withDeviceType from '../../hoc/with-device-type';

class ScrollListener extends Component {
  static propTypes = {
    onScroll: PropTypes.func.isRequired,
    isMobile: PropTypes.bool,
  };

  componentDidMount() {
    this.startListeningToOuterScroll();
  }

  componentWillUnmount() {
    if (window) {
      window.removeEventListener('scroll', this.handleScroll, false);
    }
  }

  startListeningToOuterScroll() {
    if (window) {
      window.addEventListener('scroll', this.handleScroll, false);
    }
  }

  handleScroll = throttle(scroll => this.props.onScroll(scroll), 500, { leading: true });

  render() {
    return <div />;
  }
}

export default flowRight(withDeviceType)(ScrollListener);
