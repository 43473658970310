import React, { useEffect, useState } from 'react';
import { connect } from '../../../common/components/runtime-context';
import PropTypes from 'prop-types';
import CategoryPage from '../category-page';
import CategoriesPage from '../categories-page';
import { getIsMainPageEnabled } from '../../selectors/app-settings-selectors';
import { flowRight } from 'lodash';
import { FeedType, getFeedType } from '../header-navigation';
import withExperiment from '../../hoc/with-experiment';
import { EXPERIMENT_FORUM_TABS } from '@wix/communities-forum-client-commons';
import { getCurrentUser } from '../../../common/store/current-user/current-user-selectors';

const HomePage = ({
  isMainPageEnabled,
  feedType,
  isForumTabsEnabled,
  currentSiteMemberId,
  currentLocationPath,
  currentRouterPath,
  ...props
}) => {
  if (!isForumTabsEnabled) {
    return isMainPageEnabled ? <CategoriesPage {...props} /> : <CategoryPage {...props} />;
  }

  if (feedType === FeedType.MyPosts && currentSiteMemberId) {
    return <CategoryPage siteMemberId={currentSiteMemberId} {...props} />;
  }

  if (feedType === FeedType.Categories || (!feedType && isMainPageEnabled)) {
    return <CategoriesPage {...props} />;
  }

  if (feedType === FeedType.Posts || (!feedType && !isMainPageEnabled)) {
    return <CategoryPage {...props} />;
  }

  return <CategoriesPage {...props} />;
};

HomePage.propTypes = {
  isMainPageEnabled: PropTypes.bool.isRequired,
  isForumTabsEnabled: PropTypes.bool.isRequired,
  feedType: PropTypes.string,
  currentSiteMemberId: PropTypes.string,
};

const mapRuntimeToProps = (state, ownProps, actions, host) => ({
  isMainPageEnabled: getIsMainPageEnabled(state, host.style),
  feedType: getFeedType(state),
  currentSiteMemberId: getCurrentUser(state)?.siteMemberId,
});

export default flowRight(
  connect(mapRuntimeToProps),
  withExperiment({
    isForumTabsEnabled: EXPERIMENT_FORUM_TABS,
  }),
)(HomePage);
