import { flowRight } from 'lodash';
import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from '../../../common/components/runtime-context';
import { REDUCERS } from '../../../common/components/runtime-context/reducers';
import { LAYOUT_TYPE_PINBOARD } from '@wix/communities-forum-client-commons';
import { FILTER_SHOW_ALL, FILTER_QUESTIONS, FILTER_DISCUSSIONS } from '../../constants/filtering';
import { getCategoryBySlug } from '../../../common/selectors/categories-selectors';
import { getRouteParams } from '../../../common/router/router-selectors';
import { getLayoutType } from '../../selectors/layout-selectors';
import { getPostTypeFilter } from '../../selectors/filter-selectors';
import { getCategoryPageSorting } from '../../selectors/sorting-selectors';
import withTranslate from '../../../common/components/with-translate/with-translate';

export const getOptions = t => [
  { label: t('post-filter.show-all'), value: FILTER_SHOW_ALL },
  { label: t('post-filter.questions'), value: FILTER_QUESTIONS },
  { label: t('post-filter.discussions'), value: FILTER_DISCUSSIONS },
];

class FilterSelectHeadless extends Component {
  onChange = value => {
    const {
      setPostTypeFilter,
      fetchCategoryPosts,
      categoryId,
      page,
      sort,
      currentPostTypeFilter,
    } = this.props;

    if (value === currentPostTypeFilter) {
      return;
    }

    setPostTypeFilter(value);
    fetchCategoryPosts({
      categoryId,
      page,
      sort,
      postType: value,
    });
  };

  render() {
    const { t, currentPostTypeFilter, children } = this.props;
    return children({
      options: getOptions(t),
      value: currentPostTypeFilter,
      onChange: this.onChange,
    });
  }
}

FilterSelectHeadless.propTypes = {
  t: PropTypes.func,
  onChange: PropTypes.func,
  categoryId: PropTypes.string,
  page: PropTypes.number,
  sort: PropTypes.string,
  currentPostTypeFilter: PropTypes.string,
  children: PropTypes.func,
  fetchFirstPageOnly: PropTypes.bool,
  setPostTypeFilter: PropTypes.func,
  fetchCategoryPosts: PropTypes.func,
};

const mapRuntimeToProps = (state, ownProps, actions, host) => {
  const params = getRouteParams(state);
  const { categorySlug } = params;
  const page = parseInt(params.page, 10) || 1;
  const category = getCategoryBySlug(state, categorySlug) || { _id: null };
  return {
    categoryId: category._id,
    page:
      ownProps.fetchFirstPageOnly || getLayoutType(state, host.style) === LAYOUT_TYPE_PINBOARD
        ? 1
        : page,
    currentPostTypeFilter: getPostTypeFilter(state),
    sort: getCategoryPageSorting(state, host.style),
    fetchCategoryPosts: actions.fetchCategoryPosts,
    setPostTypeFilter: actions.setPostTypeFilter,
  };
};

export default flowRight(
  withTranslate,
  connect(mapRuntimeToProps, [
    REDUCERS.ROUTER,
    REDUCERS.CATEGORIES,
    REDUCERS.FILTERING,
    REDUCERS.SORTING,
  ]),
)(FilterSelectHeadless);
