import React from 'react';

import CommentActions from '../../containers/comment-actions';
import { MODAL_TYPE_COMMENT_ACTIONS } from '../modals/action-sheet/action-sheet-modal-types';
import MoreButton from '../more-button';
import MoreButtonMobile from '../more-button-mobile';

const MoreButtonUniversalComment = ({ comment, origin, isMobile, className }) => {
  return (
    <div className={className}>
      {isMobile ? (
        <MoreButtonMobile
          type={MODAL_TYPE_COMMENT_ACTIONS}
          comment={comment}
          focusOnCloseId={`more-button-${comment._id}`}
          origin={origin}
        />
      ) : (
        <MoreButton id={`more-button-${comment._id}`}>
          <CommentActions
            comment={comment}
            focusOnCloseId={`more-button-${comment._id}`}
            origin={origin}
          />
        </MoreButton>
      )}
    </div>
  );
};

export default MoreButtonUniversalComment;
