import getMainTheme from './theme';
import styles from './theme-comment.scss';
import { mergeStyles } from './utils';

export default function getTheme(args) {
  return mergeStyles(getMainTheme(args), {
    editor: styles.editor,
    footerToolbar: styles.footerToolbar,
    footerToolbar_buttons: styles.footerToolbar_buttons,
    wrapper: styles.wrapper,
    hasFocus: styles.hasFocus,
    desktop: styles.desktop,
    text: styles.text,
    atomic: styles.atomic,
  });
}
