import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { EXPERIMENT_USE_NEW_POST_PAGE_TITLE_STYLES } from '@wix/communities-forum-client-commons';
import Link from '../link/internal-link';
import withTranslate from '../../../common/components/with-translate/with-translate';
import withExperiment from '../../hoc/with-experiment';
import styles from './post-title.scss';

const renderCategoryLink = (category, Link, t) => {
  const to = `/${category.slug}`;
  return (
    <div className={styles.link}>
      <Link to={to} className={classNames('forum-link-hover-color')}>
        <span className={styles.categoryLink}>
          {t('post-list-item.title-in-category')}&nbsp;{category.label}
        </span>
      </Link>
    </div>
  );
};

const PostSubTitle = ({
  className,
  category,
  t,
  type,
  showCategoryLink,
  isNewTitleStylesEnabled,
}) => {
  return (
    <div
      className={classNames(
        className,
        styles[type],
        styles.container,
        isNewTitleStylesEnabled ? styles.newStyle : null,
      )}
    >
      {showCategoryLink && renderCategoryLink(category, Link, t)}
    </div>
  );
};

PostSubTitle.propTypes = {
  className: PropTypes.string,
  category: PropTypes.object,
  post: PropTypes.object.isRequired,
  t: PropTypes.func,
  type: PropTypes.string,
  showCategoryLink: PropTypes.bool,
};

PostSubTitle.defaultProps = {
  showCategoryLink: false,
};

PostSubTitle.LARGE = 'large';
PostSubTitle.SEARCH = 'search';
PostSubTitle.MOBILE = 'mobile';

export default flowRight(
  withTranslate,
  withExperiment({
    isNewTitleStylesEnabled: EXPERIMENT_USE_NEW_POST_PAGE_TITLE_STYLES,
  }),
)(PostSubTitle);
