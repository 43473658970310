import React from 'react';
import withDeviceType from './with-device-type';

const forDesktopOnly = Component => {
  return withDeviceType(({ isMobile, ...props }) => {
    return !isMobile ? <Component {...props} /> : null;
  });
};

export default forDesktopOnly;
