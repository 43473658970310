import React, { Component } from 'react';
import { connect } from '../../common/components/runtime-context';
import { isString, memoize, pick, flowRight, noop, identity, mapValues, mapKeys } from 'lodash';
import {
  createHashtagHref,
  createHashtagPath,
  HTML_IFRAME_SRC,
  MAX_PINTEREST_IMAGE_SIZE,
} from '../components/rich-content-editor/utils';
import { RCE_FILE_DOWNLOAD_WILL_BEGIN_SHORTLY } from '../components/messages/message-types';
import { supportedMimeTypes } from '../../common/services/wix-media/supported-mime-types';
import { isIos } from '../../common/services/detect-platform';
import {
  customStyleFn,
  getTextColorSchema,
  styleSelectionPredicate,
  viewerCustomStyleFn,
} from '../components/rich-content-editor/text-color-utils';
import { pluginLink } from 'wix-rich-content-plugin-link/loadable/viewer';
import { pluginHashtag } from 'wix-rich-content-plugin-hashtag/viewer';
import { pluginImage } from 'wix-rich-content-plugin-image/loadable/viewer';
import { pluginVideo } from 'wix-rich-content-plugin-video/loadable/viewer';
import { pluginDivider } from 'wix-rich-content-plugin-divider/viewer';
import { pluginHtml } from 'wix-rich-content-plugin-html/loadable/viewer';
import { pluginMentions } from 'wix-rich-content-plugin-mentions/viewer';
import { pluginCodeBlock } from 'wix-rich-content-plugin-code-block/viewer';
import { pluginTextColor, pluginTextHighlight } from 'wix-rich-content-plugin-text-color/viewer';
import { pluginGiphy } from 'wix-rich-content-plugin-giphy/loadable/viewer';
import { pluginEmoji } from 'wix-rich-content-plugin-emoji/viewer';
import { pluginFileUpload } from 'wix-rich-content-plugin-file-upload/loadable/viewer';
import { pluginLinkPreview } from 'wix-rich-content-plugin-link-preview/loadable/viewer';
import { pluginVerticalEmbed } from 'wix-rich-content-plugin-vertical-embed/viewer';
import { pluginSpoiler } from 'wix-rich-content-plugin-spoiler/viewer';
import { pluginCollapsibleList } from 'wix-rich-content-plugin-collapsible-list/loadable/viewer';
import { pluginPoll } from 'wix-rich-content-plugin-social-polls/loadable/viewer';
import { getIsStorageLimitReached } from '../selectors/forum-data-selectors';
import { uploadFileToWixMedia as uploadFileToWixMediaAction } from '../../common/services/wix-media/wix-media-file-upload-service';
import { uploadVideoToWixMedia as uploadVideoToWixMediaAction } from '../../common/services/wix-media/wix-media-video-upload-service';
import {
  getImageUrl,
  createSiteMembersFetchServiceDebounced,
  URI,
  PLUGINS,
} from '@wix/communities-forum-client-commons';

import withTranslate from '../../common/components/with-translate/with-translate';
import {
  getIsUploadLimiterEnabled,
  getUploadSizeLimit,
  getEnabledRcePlugins,
  getIsSocialSharingLinksEnabled,
} from '../selectors/app-settings-selectors';
import { getUploadedRegistry } from '../selectors/uploaded-registry-selectors';
import {
  getApiBaseUrl,
  getInstance,
  getIsMobile,
  getLanguage,
  getRicosBiParams,
} from '../../common/store/basic-params/basic-params-selectors';

import { getLocation, getSectionUrl } from '../../common/store/location/location-selectors';
import { wrapUploadImageAction } from '../actions/image-upload/upload-wrapper';
import { DEFAULT_PLUGINS } from '../components/rich-content-editor/constants';
import { isMemberAreaInstalled } from '../../common/store/communities-context/communities-context-selectors';
import withDeviceType from './with-device-type';
import { pluginOrder, pluginTypeMap } from '../containers/wix-comments/plugins';
import { getVisibleVerticalEmbeds } from '../selectors/vertical-embed-selectors';
import withAuth from './with-auth';
import { resolveFileUrl } from '@wix/ricos-viewer';
import { getMetaSiteId } from '../../common/store/instance-values/instance-values-selectors';

// https://wix.slack.com/archives/CAKBA7TDH/p1601553610052000
const memoizeFn = memoize.Cache === WeakMap ? identity : memoize;

export const withRcePluginsConfig = Comp => {
  class WithEditorPluginConfigs extends Component {
    state = {
      userColors: [],
    };

    getImagePluginConfig = isViewer => {
      const { showPin, pageUrl, isMobile } = this.props;
      const config = {
        imageProps: showPin
          ? src => ({
              'data-pin-url': pageUrl,
              'data-pin-media': isString(src)
                ? src
                : getImageUrl(src, MAX_PINTEREST_IMAGE_SIZE, MAX_PINTEREST_IMAGE_SIZE),
            })
          : { 'data-pin-nopin': true },
      };

      if (isViewer) {
        config.disableExpand = isMobile;
      } else {
        config.toolbar = {
          hidden: ['sizeFullWidth'],
        };
      }

      return config;
    };

    getVideoPluginConfig = () => {
      return {
        toolbar: {
          hidden: [],
        },
        getVideoUrl: src => `https://video.wixstatic.com/${src.pathname}`,
        enableCustomUploadOnMobile: true,
      };
    };

    getFileUploadPluginConfig = () => {
      return {
        accept: supportedMimeTypes.join(','),
      };
    };

    getFileUploadPluginViewerConfig = () => {
      const {
        showMessage,
        requestFileDownloadUrlPromisified,
        fileDownloadRequest,
        metaSiteId,
        instance,
      } = this.props;
      return {
        resolveFileUrl: imgEntityData => {
          // This is needed for backwards compatibility.
          // Forum used to upload files via Node API service, which resulted in different entity structure
          // We are assuming that if entity has a 'path', it was uploaded via Node API, otherwise
          // It's assumed that RICOS upload logic was used.
          if (!imgEntityData.path) {
            return resolveFileUrl(metaSiteId, instance)(imgEntityData);
          }

          isIos() && showMessage(RCE_FILE_DOWNLOAD_WILL_BEGIN_SHORTLY);
          const getBiEventData = isSuccessful => ({
            fileId: imgEntityData.id,
            fileExtension: imgEntityData.type,
            mimeType: imgEntityData.mimeType,
            size: imgEntityData.size,
            isSuccessful,
            origin,
          });

          return requestFileDownloadUrlPromisified(imgEntityData.path)
            .then(data => {
              fileDownloadRequest(getBiEventData(true));
              return data.downloadUrl;
            })
            .catch(() => fileDownloadRequest(getBiEventData(false)));
        },
      };
    };

    getColorPluginsConfig = isHighlight => {
      const colorScheme = getTextColorSchema(this.props.style);
      return {
        colorScheme,
        styleSelectionPredicate: styleSelectionPredicate(colorScheme),
        customStyleFn: customStyleFn(viewerCustomStyleFn(colorScheme, isHighlight)),
        getUserColors: () => this.state.userColors,
        onColorAdded: c => this.setState({ userColors: this.state.userColors.concat(c) }),
      };
    };

    getColorPluginsViewerConfig = isHighlight => {
      const colorScheme = getTextColorSchema(this.props.style);
      return {
        customStyleFn: viewerCustomStyleFn(colorScheme, isHighlight),
        styleSelectionPredicate: styleSelectionPredicate(colorScheme),
      };
    };

    onHashTagClick = event => {
      event.preventDefault();
      event.stopPropagation();
      const pathname = createHashtagPath(event.target.innerText);
      this.props.navigateWithinForum(pathname);
    };

    filterEnabledPlugins = plugins =>
      pick(plugins, [...DEFAULT_PLUGINS, ...this.props.enabledPlugins]);

    getPluginsConfig = memoizeFn(() => {
      const {
        enabledVerticalEmbedPlugins,
        instance,
        apiBaseUrl,
        sectionUrl,
        isMembersAreaInstalled,
        navigateToProfile,
      } = this.props;
      const plugins = {
        [PLUGINS.IMAGE]: {
          viewerModule: pluginImage,
          config: this.getImagePluginConfig(),
          viewerConfig: this.getImagePluginConfig(true),
        },
        [PLUGINS.VIDEO]: {
          viewerModule: pluginVideo,
          config: this.getVideoPluginConfig(),
          viewerConfig: {
            // Function is invoked when rendering video which has relative URL.
            // You should take the pathname and form a full URL.
            getVideoUrl: src => `https://video.wixstatic.com/${src.pathname}`,
          },
        },
        [PLUGINS.FILE_UPLOAD]: {
          viewerModule: pluginFileUpload,
          config: this.getFileUploadPluginConfig(),
          viewerConfig: this.getFileUploadPluginViewerConfig(),
        },
        [PLUGINS.GIPHY]: {
          viewerModule: pluginGiphy,
          config: {
            giphySdkApiKey: '8LVphUXpyDK77BY7BhYMXgFU6TwhSKkA',
            componentDataDefaults: { config: { size: 'small', alignment: 'center' } },
            insertToolbars: ['SIDE', 'FOOTER'],
          },
        },
        [PLUGINS.EMOJI]: {
          viewerModule: pluginEmoji,
        },
        [PLUGINS.CODE_BLOCK]: {
          viewerModule: pluginCodeBlock,
        },
        [PLUGINS.COLLAPSIBLE_LIST]: {
          viewerModule: pluginCollapsibleList,
        },
        [PLUGINS.DIVIDER]: {
          viewerModule: pluginDivider,
        },
        [PLUGINS.HTML]: {
          viewerModule: pluginHtml,
          config: {
            htmlIframeSrc: HTML_IFRAME_SRC,
          },
        },
        [PLUGINS.VERTICAL_EMBED]: {
          viewerModule: pluginVerticalEmbed,
          config: {
            exposeEmbedButtons: enabledVerticalEmbedPlugins,
          },
        },
        [PLUGINS.LINK]: {
          viewerModule: pluginLink,
        },
        [PLUGINS.LINK_PREVIEW]: {
          viewerModule: pluginLinkPreview,
        },
        [PLUGINS.TEXT_COLOR]: {
          viewerModule: pluginTextColor,
          config: this.getColorPluginsConfig(),
          viewerConfig: this.getColorPluginsViewerConfig(),
        },
        [PLUGINS.TEXT_HIGHLIGHT]: {
          viewerModule: pluginTextHighlight,
          config: this.getColorPluginsConfig(true),
          viewerConfig: this.getColorPluginsViewerConfig(true),
        },
        [PLUGINS.HASHTAG]: {
          viewerModule: pluginHashtag,
          config: {
            createHref: () => '',
            onClick: noop,
          },
          viewerConfig: {
            onClick: this.onHashTagClick,
            createHref: createHashtagHref(sectionUrl),
          },
        },
        [PLUGINS.MENTIONS]: {
          viewerModule: pluginMentions,
          config: {
            getMentions: createSiteMembersFetchServiceDebounced({ instance, apiBaseUrl }),
            entryHeight: 30,
            visibleItemsBeforeOverflow: 5,
          },
          viewerConfig: {
            onMentionClick: isMembersAreaInstalled
              ? mention => navigateToProfile({ memberId: mention.id, memberSlug: mention.slug })
              : null,
            getMentionLink: noop,
          },
        },
        [PLUGINS.SPOILER]: {
          viewerModule: pluginSpoiler,
          config: {
            supportedPlugins: this.getSupportedSpoilerPlugins(),
          },
        },
        [PLUGINS.POLLS]: {
          viewerModule: pluginPoll,
        },
      };
      return this.filterEnabledPlugins(plugins);
    });

    getSupportedSpoilerPlugins = () => {
      const { isAuthenticated, enabledPlugins } = this.props;
      return isAuthenticated
        ? [PLUGINS.IMAGE, PLUGINS.VIDEO]
            .filter(p => enabledPlugins.includes(p))
            .map(p => pluginTypeMap[p])
        : [];
    };

    orderEditorPlugins = plugins => {
      const orderedKeys = pluginOrder.map(p => pluginTypeMap[p]);
      return orderedKeys.reduce((acc, key) => {
        const plugin = plugins[key];
        if (plugin) {
          return { ...acc, [key]: plugin };
        }
        return acc;
      }, {});
    };

    loadEditorPluginsAsync = memoizeFn(async () => {
      const { editorPluginsConfig } = await import(
        /* webpackChunkName: "ricos-editor-plugins-config" */ '../containers/wix-comments/editor-plugins-lazy'
      );
      const enabledConfigs = this.filterEnabledPlugins(editorPluginsConfig);
      const pluginsConfig = this.getPluginsConfig();
      const editorConfig = mapValues(enabledConfigs, (value, key) => ({
        ...value,
        config: pluginsConfig[key].config,
      }));
      const editorPlugins = mapKeys(editorConfig, (val, key) => pluginTypeMap[key]);
      return this.orderEditorPlugins(editorPlugins);
    });

    render() {
      return (
        <Comp
          {...this.props.originalProps}
          editorPluginsConfig={this.getPluginsConfig()}
          loadEditorPluginsAsync={this.loadEditorPluginsAsync}
        />
      );
    }
  }

  const mapRuntimeToProps = (state, ownProps, actions, host) => ({
    style: host.style,
    locale: getLanguage(state),
    pageUrl: new URI(getLocation(state).url).search('').toString(),
    instance: getInstance(state),
    sectionUrl: getSectionUrl(state),
    enabledPlugins: getEnabledRcePlugins(state, host.style),
    showPin: getIsSocialSharingLinksEnabled(state, host.style),
    uploadedRegistry: getUploadedRegistry(state),
    removeItemFromUploadedRegistry: actions.removeItemFromUploadedRegistry,
    uploadImage: wrapUploadImageAction(actions.uploadImageRCEPromisified, getIsMobile(state)),
    fetchOembed: actions.fetchOembed,
    fileUploaded: actions.fileUploaded,
    showMessage: actions.showMessage,
    waitForSuccessfulResponsePromisified: actions.waitForSuccessfulResponsePromisified,
    uploadFileToWixMedia: uploadFileToWixMediaAction(actions.requestCredentialsV2Promisified),
    uploadVideoToWixMedia: uploadVideoToWixMediaAction(actions.requestCredentialsPromisified),
    apiBaseUrl: getApiBaseUrl(state),
    isUploadLimiterEnabled: getIsUploadLimiterEnabled(state, host.style),
    uploadSizeLimit: getUploadSizeLimit(state, host.style),
    isStorageLimitReached: getIsStorageLimitReached(state),
    userEventsHitUploadSizeLimit: actions.userEventsHitUploadSizeLimit,
    ricosBiParams: getRicosBiParams(state),
    experiments: state.experiments,
    enabledVerticalEmbedPlugins: getVisibleVerticalEmbeds(state, host.style),
    requestFileDownloadUrlPromisified: actions.requestFileDownloadUrlPromisified,
    fileDownloadRequest: actions.fileDownloadRequest,
    originalProps: ownProps,
    navigateWithinForum: actions.navigateWithinForum,
    isMembersAreaInstalled: isMemberAreaInstalled(state),
    navigateToProfile: actions.navigateToProfile,
    metaSiteId: getMetaSiteId(state),
  });

  return flowRight(
    connect(mapRuntimeToProps),
    withTranslate,
    withDeviceType,
    withAuth,
  )(WithEditorPluginConfigs);
};
