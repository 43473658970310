import React from 'react';

export const AnonymousIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" {...props}>
    <g fill="none" fill-rule="evenodd">
      <circle cx="14" cy="14" r="14" fill="#979797" />
      <path
        fill="#D8D8D8"
        d="M14 0c7.732 0 14 6.28 14 14.026 0 3.56-1.323 6.81-3.504 9.282l-.177.198c-1.923-3.601-5.836-6.203-10.386-6.203-4.684 0-8.758 2.257-10.652 5.747C1.234 20.61 0 17.463 0 14.026 0 6.28 6.268 0 14 0zm.345 5.027c-2.974 0-5.385 2.415-5.385 5.395 0 2.98 2.41 5.395 5.385 5.395 2.973 0 5.384-2.416 5.384-5.395 0-2.98-2.41-5.395-5.384-5.395z"
      />
      <path d="M14 0c7.732 0 14 6.268 14 14s-6.268 14-14 14S0 21.732 0 14 6.268 0 14 0zm0 1.12C6.887 1.12 1.12 6.887 1.12 14S6.887 26.88 14 26.88 26.88 21.113 26.88 14 21.113 1.12 14 1.12z" />
    </g>
  </svg>
);
