import { isIos } from '../../common/services/detect-platform';

// focus on input element during user initiated interaction will open soft keyboard
export const triggerIosKeyboard = () => {
  if (!isIos()) {
    return;
  }
  const i = document.createElement('input');
  const id = 'fake-input';
  i.setAttribute('id', id);
  i.style.fontSize = '16px';
  i.style.position = 'fixed';
  i.style.width = '1px';
  i.style.height = '1px';
  i.style.top = '0';
  i.style.opacity = '0';
  i.style.zIndex = '99999';

  document.body.appendChild(i);
  const inputEl = document.getElementById(id);
  inputEl.focus();
  setTimeout(() => document.body.removeChild(inputEl), 1000);
};
